import React from 'react'
import EventAvatar from '../../EventAvatar'
import moment from 'moment'
import PropTypes from "prop-types";

import "./dialog-item.scss"

// Обрезать текст последнего сообщения (макс. 75 символов)
const ChatDialogItem = props => {
	return (
		<div className="dialog-item">
			<div className="dialog-item__avatar">
				<EventAvatar src={props.image} />
			</div>
			<div>
				<div className="dialog-item__name">{props.title}</div>
				<div className="dialog-item__last-message__text">{props.lastMessage.message}</div>
			</div>
			{props.notificationsCount !== 0 && <span className="dialog-item__notifications">{props.notificationsCount }</span>}
			<div className="dialog-item__last-message__time">{moment(props.lastMessage.createdAt).format('DD MMM')}</div>
		</div>
	)
};

ChatDialogItem.propTypes = {
	lastMessage: PropTypes.object
};

ChatDialogItem.defaultProps = {
	lastMessage: {}
};

export default ChatDialogItem
