import React, {useEffect} from "react";
import {withRouter} from "react-router";

const ScrollToTop = ({ children, location: { pathname } }) => {
    useEffect(() => {
        console.log("window.history", window.history);
        window.scrollTo(0, 0);
    }, [pathname]);

    return children || null;
};

export default withRouter(ScrollToTop);
