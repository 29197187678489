import React, { Fragment } from "react";
import classNames from 'classnames'
import PropTypes from 'prop-types'
import TextareaAutosize from 'react-textarea-autosize';
import './style.scss'

const FormTextArea = ({value, placeholder, fullWidth, label, required, className = "", ...rest}) => {

    const containerClassNames = classNames({
        "form-field": true,
        "form-field__full-width": fullWidth
    });
    className = classNames("form-field__input", className);
    const inputProps = {...rest};

    return (
        <div className={containerClassNames}>
            {label && <label>{label}{required && <span className="text-danger">*</span>}</label>}
            <TextareaAutosize minRows = {5} className={className} value={value}  placeholder={placeholder} {...inputProps }/>
        </div>
        )
};

const FormTextAreaAdapter = ({ input, meta, ...rest }) => {
    return (
        <Fragment>
            <FormTextArea
                {...rest}
                {...input}
                onChange={(e) => {
                    input.onChange(e.target.value)
                }}
            />
            { meta.error && meta.touched && <span className="form-field__error">{ meta.error }</span> }
        </Fragment>
    )
}


FormTextArea.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    fullWidth: PropTypes.bool,
    value: PropTypes.string,
    required: PropTypes.bool
};

export {
    FormTextAreaAdapter
}

export default FormTextArea;
