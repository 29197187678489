import React from 'react'
import PropTypes from "prop-types";

const IconEmail = props => (
    <svg className={props.className} width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.0236 14.984C10.9078 14.984 0.00732422 6.1993 0.00732422 6.1993V5.23405C0.00732422 4.03805 0.977991 3.06738 2.17616 3.06738H23.871C25.0702 3.06738 26.0409 4.03805 26.0409 5.23405L26.0236 6.31738C26.0236 6.31738 15.2412 14.984 13.0236 14.984ZM13.0236 17.9632C15.343 17.9632 26.0236 9.56738 26.0236 9.56738L26.0409 22.5674C26.0409 23.7634 25.0702 24.7341 23.871 24.7341H2.17616C0.979074 24.7341 0.00732422 23.7634 0.00732422 22.5674L0.0246575 9.56738C0.0235742 9.56738 10.9078 17.9632 13.0236 17.9632Z" fill={props.fill}/>
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="26" height="26" fill="white" transform="translate(0.0410156 0.900879)"/>
            </clipPath>
        </defs>
    </svg>
);

IconEmail.propTypes = {
    size: PropTypes.number,
    fill: PropTypes.string,
    className: PropTypes.string
};

IconEmail.defaultProps = {
    size: 16,
    fill: "#18B196",
    className: ""
};

export default IconEmail



