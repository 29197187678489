const initialState = {};

export default (state = initialState, action = {}) => {
    switch(action.type) {
        case 'OPEN_DRAWER':
            return {...state, drawer: true};
        case 'CLOSE_DRAWER':
            return {...state, drawer: false};
        default:
            return state
    }
}
