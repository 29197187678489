import React, { useState, useEffect,  Fragment } from "react";
import { Form, Field } from "react-final-form";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import _ from "underscore";
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
} from "react-places-autocomplete";
import Select from "react-select";
import Creatable from 'react-select/creatable';
import { FormTextAreaAdapter } from "../../components/FormTextArea";
import FormField, { FormFieldAdapter } from "../../components/FormField";
import BaseButton from "../../components/Button";
import FormUpload from "../../components/FormUpload";
import {
	required,
	composeValidators,
	minValue,
	maxValue
} from "../../helpers/valdations";

import "./style.scss";
import { createEvent, editEvent } from "../../actions/event";
import { getPlace } from "../../actions/places";

import useAuthorized from '../../utils/useAuthorized'
import { push } from "connected-react-router";
import {withRouter} from "react-router";
import {toast} from "react-toastify";


const dateValidator = ({min, max}) => value =>{
	console.log("minDate => ",min,"  value => ",value);
	if(!value){
		console.log("no date vvalue!")
		return "Required";
	}else if(min && moment(value).isBefore(moment(min))){
		console.log("is before!!!")
		return "Can't create event in past";
	}else if(max && moment(value).isAfter(max)){
		console.log("is after!!!")
		return "The date is too late";
	}else{
		console.log("date is ok ",moment(value).toDate());
		return undefined;
	}
}


const eventTypes = [
	{value:"session", label:"Training session"},
	{value:"camp", label:"Training camp"},
	{value:"facility", label:"Training facility"},
	{value:"recovery", label:"Recovery"},
]

const eventSubTypes = {
	session:[
		{ value: "pool", label: "Pool swim" },
		{ value: "open_water", label: "Open water swim" },
		{ value: "run", label: "Running" },
		{ value: "cycling", label: "Cycling" },
	],
	camp:null,
	facility:[
		{value:'pool', label:"Pool swimming"},
        {value:'open_water',label:"Open water swimming"},
        {value:'cycling_track',label:"Track cycling"},
        {value:'running_track',label:"Track running"},
        {value:'turbo_trainer',label:"Turbo trainer"},
        {value:'outdoor_run',label:"Outdoor run"},
        {value:'outdoor_cycling',label:"Outdoor cycling"}
	],
	recovery:[
		{value:"stretching", label:"Stretching"},
		{value:"physio", label:"Physio"}
	]
};

const activities = [
	{ value: "running", label: "Running" },
	{ value: "swimming", label: "Swimming" },
	{ value: "cycling", label: "Cycling" },
];

const OrganiseEvent = withRouter(props => {
	const dispatch = useDispatch();
	const isAuthorized = useAuthorized();
	const [location, setLocation] = useState({ coordinates: [] });
	const [address, setAddress] = useState("");
	const event = useSelector(({place}) => place);
	console.log("event ",event);
	const [initialValues, setInitialValues] = useState({});
	const isEdit = props.match && props.match.params.id;
	const setEditor = async () => {
		if(isEdit) {
			const event = await dispatch(getPlace(props.match.params.id));
			if(event){
				setAddress(event.address);
				geocodeByAddress(event.address)
					.then(results => {
						setAddress(results[0].formatted_address);
						return getLatLng(results[0]);
					})
					.then(latLng => {
						setLocation({ coordinates: [latLng.lat, latLng.lng] });
					})
					.catch(error => console.error("Error geocoding", error));

				console.log("event => ",event);

				if(props.match && props.match.params.id && event){
					console.log("setting initial values")
					let subTypeInitialValue = null;
					try{
						subTypeInitialValue = eventSubTypes[event.type].find((est)=>{
							return est.value == event.subtype
						});
					}catch(e){
						console.error("error parasing ",e);
					}
					setInitialValues(
						{
							name: event.name,
							start_date: event.dateStart?moment(event.dateStart).format("YYYY-MM-DD"):null,
							start_time: event.dateStart?moment(event.dateStart).format("HH:mm"):null,
							end_date: event.dateEnd?moment(event.dateEnd).format("YYYY-MM-DD"):null,
							end_time: event.dateEnd?moment(event.dateEnd).format("HH:mm"):null,
							photo: event.photo,
							price: event.price,
							activities: event.activities ? event.activities.map((activity) => {
								return activities.find((select_activity) => select_activity.value === activity);
							}) : [],
							type: eventTypes.find((et)=>{
								return et.value == event.type
							}),
							subtype: subTypeInitialValue,
							
							// ? event.subtype.map( subtype => {
								// return eventSubTypes.find((eventSubType) => eventSubType.value === subtype);
							// }) : [],
							description: event.description,
							worktime:event.worktime,
							details: event.details,
							phone: event.phone,
							outdoor: event.outdoor,
							tags: event.tags ? event.tags.map((t )=>{
								return {
									value:t,
									label: t
								}
							}):[]
						}
					);
				}
			}
		} else {
			setInitialValues({});
		}
	};

	useEffect(() => {
		setEditor();
	},[isEdit]);

	const changeEventStatus = async (event_status) => {
		const {status} = await dispatch(editEvent({_id: props.match.params.id, status: event_status}));
		if(status === "ok"){
			switch (event_status) {
				case "deleted":
					dispatch(push("/manage-events"));
					break;
				case "published":
					toast.success("Event now published");
					break;
				case "draft":
					toast.success("Event now in draft");
					break;
			}
		}
	};

	const onSubmit = async (values) => {
		console.log("onSubmit => ",values);

		const { end_date, end_time, start_date, start_time } = values;


		let dateStart = null;
		if(start_date && start_time){
			dateStart =  moment(start_date + " " + (start_time?start_time:"00:00") ).format("YYYY-MM-DD hh:mm");
		}
		let dateEnd = null;
		console.log("end_time ",end_time);
		console.log("end_date ",end_date);

		if(end_time  && end_date){
			console.log("END ===>",end_date + " " + (end_time?end_date:"00:00"));
			dateEnd = moment(end_date + " " + (end_time?end_time:"00:00")).format("YYYY-MM-DD hh:mm");
		}
		console.log("dateEnd ",dateEnd);
		let value = { ...values, dateStart, dateEnd, location, address };

		delete value.start_date;
		delete value.start_time;
		delete value.end_date;
		delete value.end_time;
		
		console.log("VALUE BEFORE SAVE ",value)

		// Sort activities for backend
		if(value.activities && !_.isEmpty(value.activities)) {
			let activities = [];
			value.activities.forEach(activ => {
				console.log("activ ",activ)
				if(activ){
					activities.push(activ.value)
				}
			});
			value.activities = activities
		}

		// Sort activities for backend
		if(!_.isEmpty(value.tags)) {
			let tags = [];
			value.tags.forEach(tag => {
				console.log("ttag ",tag);
				if(tag){
					tags.push(tag.value)
				}
			});
			value.tags = tags
		}
		console.log("VALUE AFTTER SAVE ",value)
		// Sort event types for backend
		// if(!_.isEmpty(value.subtype)) {
			// let subtype = [];
			// value.subtype.forEach(stype => {
				// subtype.push(stype.value)
			// });
			// value.subtype = subtype
		// }
		if(!_.isEmpty(value.subtype) && value.subtype.value) {
			value.subtype = value.subtype.value;
		}

		if(!_.isEmpty(value.type) && value.type.value) {
			value.type = value.type.value;
		}
		if(isEdit) {
			const {status} = await dispatch(editEvent({_id: props.match.params.id, ...value}));
			if(status === "ok"){
				toast.success("Event successfully updated!");
			}
		} else {
			const {event: {_id}} = await dispatch(createEvent(value));
			dispatch(push(`/places/${_id}`));
		}
	};

	const handleSelect = (address) => {
		geocodeByAddress(address)
			.then(results => {
				console.log("result", results);
				setAddress(results[0].formatted_address);
				return getLatLng(results[0]);
			})
			.then(latLng => {
				console.log("Success", latLng);
				setLocation({ coordinates: [latLng.lat, latLng.lng] });
			})
			.catch(error => console.error("Error", error));
	};

	const googleMapsAutocompleteRender = ({ getInputProps, getSuggestionItemProps, suggestions }) => (
		<div className="autocomplete-root">
			<FormField label="Address" required fullWidth { ...getInputProps() } />
			{ suggestions && suggestions.length > 0 && (
				<div className="autocomplete-dropdown-container">
					{ suggestions.map(suggestion => (
						<div { ...getSuggestionItemProps(suggestion) }>
							<span>{ suggestion.description }</span>
						</div>
					)) }
				</div>
			) }
		</div>
	);
	return (
		<div className="org-event">
			<Form
				initialValues = {initialValues}
				onSubmit={ onSubmit }
				render={ ({ handleSubmit, pristine, invalid, values }) => {

					let subs = [];
					if(values.type &&
						values.type.value &&
						eventSubTypes[values.type.value]
					){
						
						subs = eventSubTypes[values.type.value]
					}
					console.log("values ",values);
					return(
					<Fragment>
						<form onSubmit={ handleSubmit }>
							<div className="container-lg">
								<h2 className="org-event__title">Create an event or facility</h2>
								{/* EVENT TITLE */ }
								<div className="form__group">
									<Field
										name={ "name" }
										fullWidth
										required
										validate={required}
										label="Event Title"
										component={ FormFieldAdapter }
									/>
								</div>


								{/* EVENT TYPE */}
								<div className="form__group">
									<p className="form__group__label">Type<span className="required">*</span></p>
									<Field name="type" validate={required}>
										{ props => (
											<Select
												options={ eventTypes }
												onChange={ val => props.input.onChange(val) }
												// isMulti
												value = {props.input.value}
												className="form__group__category"
												placeholder="Select type..."
												components={ {
													DropdownIndicator: () => null,
													IndicatorSeparator: () => null,
												} }/>
										) }
									</Field>
								</div>

								{/* EVENT SUBTYPE */}
								{(values.type && values.type.value == "camp")?(null):(
									<div className="form__group">
										<p className="form__group__label">Category<span className="required">*</span></p>
										<Field name="subtype" validate={required}>
											{ props => (
												<Select
													options={
														(
															values.type &&
															values.type.value &&
															eventSubTypes[values.type.value] 
														)?eventSubTypes[values.type.value]:[]
													}
													onChange={ val => props.input.onChange(val) }
													// isMulti
													value = {props.input.value}
													className="form__group__category"
													placeholder="Select category..."
													components={ {
														DropdownIndicator: () => null,
														IndicatorSeparator: () => null,
													} }/>
											) }
										</Field>
									</div>
								)}

								{/* EVENT LOCATION */ }
								<div className="form__group">
									<PlacesAutocomplete
										onChange={ val => setAddress(val) }
										value={ address }
										onSelect={ handleSelect }
										searchOptions={{
											//Don't need this when creating a place
											//types:["(cities)"],
											componentRestrictions: {country: "uk"}
										}}
									>
										{ googleMapsAutocompleteRender }
									</PlacesAutocomplete>
								</div>

								{/* EVENT PRICE */ }
								<div className="form__group">
									<Field
										name={ "price" }
										label="Entry price (leave empty if it's is free)"
										type="number"
										validate={minValue(0)}
										units="£"
										component={ FormFieldAdapter }
									/>
								</div>

								{/* EVENT DATE */ }
								{(values.type && values.type.value == "session")?(
									<div className="form__grid col-3 gap-5">
										<div className="form__group form-dates">
											<Field
												name={ "start_date" }
												type="date"
												required
												validate={dateValidator({min:moment().toDate(),  max:moment().add(5,'years').toDate()}) }
												label="Starts"
												component={ FormFieldAdapter }
											/>
											<Field
												name={ "start_time" }
												type="time"
												// validate={required}
												component={ FormFieldAdapter }
											/>
										</div>
										<div className="form__group form-dates">
											<Field
												name={ "end_date" }
												type="date"
												required
												// validate={required}
												validate={dateValidator({min:moment().toDate(), max:moment().add(5,'years').toDate()}) }
												label="Ends"
												component={ FormFieldAdapter }
											/>
											<Field
												name={ "end_time" }
												type="time"
												// validate={required}
												component={ FormFieldAdapter }
											/>
										</div>
									</div>
								):null}

								{/* EVENT IMAGE */ }
								<div className="form__group">
									<Field name="photo" allowNull>
										{ props =>
											<Fragment>
												<FormUpload
												label={ "Image" }
												meta={props.meta}
												value = {props.input.value}
												onUpload={ imageUrl => props.input.onChange(imageUrl) }/>
											</Fragment>
										}
									</Field>
								</div>

								{/* EVENT ACTIVITIES */}
								{(values.type && values.type.value !== "recovery")?(
									<div className="form__group">
										<p className="form__group__label">Activities<span className="required">*</span></p>
										<Field name="activities" validate={required}>
											{ props => {
												return (
													<Select
														defaultValue={initialValues.activities}
														options={activities}
														onChange={val => {
															props.input.onChange(val);
														}}
														value = {props.input.value}
														isMulti
														className="form__group__category"
														placeholder="Select activities..."
														components={{
															DropdownIndicator: () => null,
															IndicatorSeparator: () => null,
													}}/>
												)
											}}
										</Field>
									</div>
								):(
									<div className="form__group">
										<p className="form__group__label">Tags</p>
										<Field name="tags">
											{ props => {
												return (
													<Creatable
														defaultValue={initialValues.tags}
														onChange={val => {
															console.log("tag changed ",val);
															props.input.onChange(val);
														}}
														value = {props.input.value}
														isMulti
														className="form__group__category"
														placeholder="Add tags..."
														components={{
															DropdownIndicator: () => null,
															IndicatorSeparator: () => null,
													}}/>
												)
											}}
										</Field>
									</div>
								)}


								

								
								{/* EVENT DESCRIPTION */}
								<div className="form__group">
									<Field
										name={ "description" }
										fullWidth
										required
										validate={required}
										placeholder={"Hey there! Thanks a lot for adding this facility. You might want to add some extra info for fellow trimates:\nEg:\nVenue size (25m Pool /400m track), Nearest public transport, if there are lockers, showers, cafés etc. Add any other information that Trimates might find useful"}
										label="Description"
										component={ FormTextAreaAdapter }
									/>
								</div>


								{(values.type && values.type && values.type.value !== "session")?(
									<Fragment>
										<div className="form__group">
											<Field
												name={ "details" }
												fullWidth
												placeholder={"Enter facility details"}
												label="Details"
												component={ FormTextAreaAdapter }
											/>
										</div>
										<div className="form__group">
											<Field
												name={ "worktime" }
												fullWidth
												placeholder={"Enter work hours"}
												label="Work hours"
												component={ FormTextAreaAdapter }
											/>
										</div>

										<div className="form__group">
											<Field
												name={ "phone" }
												fullWidth
												placeholder={"Enter phone number"}
												label="Phone number"
												component={ FormFieldAdapter }
											/>
										</div>

										<div className="form__group">
											<Field
												// value={}
												name={ "outdoor" }
												fullWidth
												placeholder={"Outdoor?"}
												label="Outdoor?"
												type="checkbox"
												// component={ FormFieldAdapter }
												component='input'
											/>
											{" "}Outdoor?
										</div>


									</Fragment>
								):null}

							</div>
							<div className="form__submit-container">
								{!isEdit && (
									<h2 className="form__submit-title">
										Nice Job. You are almost done.
									</h2>
								)}
								<div className="form__submit-controls">
									<BaseButton type="submit" onClick={()=>{
										console.log("clicked submit");
									}}>
										{isEdit ? "Update": "Make it live" }
									</BaseButton>
									{isEdit && props.match.params.id ? (
										<Fragment>
											<BaseButton to={`/places/${event._id}`}>Preview</BaseButton>
											{event.status === "published" && (
												<BaseButton type="button" onClick={() => changeEventStatus("draft")} color="grey">Draft</BaseButton>
											)}
											{event.status === "draft" && (
												<BaseButton type="button" onClick={() => changeEventStatus("published")} color="green">Publish</BaseButton>
											)}
											<BaseButton type="button" onClick={() => changeEventStatus("deleted")} color="red">Delete</BaseButton>
										</Fragment>
									) : null}
								</div>
							</div>
						</form>
					</Fragment>
				) }}
			/>
		</div>
	);
});

OrganiseEvent.propTypes = {};

export default OrganiseEvent;
