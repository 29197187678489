import {combineReducers} from "redux";
import { connectRouter } from 'connected-react-router'
import places from './places'
import place from "./place";
import chats from './chats';
import chat from './chat';
import me from "./me";
import notifications from "./notifications";
import navigation from "./navigation";


export default (history) => combineReducers({
	places,
	place,
	me,
	chats,
	chat,
	navigation,
	notifications,
	router: connectRouter(history)
});
