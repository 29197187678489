import React from "react"
import { Route } from "react-router-dom"
import Header from "../../Header";
import Footer from "../../Footer";
import classNames from "classnames";
import './style.scss'

const AppLayout = ({ component: Component, headerSearch, fullPage = false, hideFooter = false, ...rest }) => {
	const  layout_classnames = classNames({
		"app-layout": true,
		"fullpage": fullPage
	});

	return (
		<Route { ...rest } render={ matchProps => (
			<div className={layout_classnames}>
				<Header color='primary' headerSearch = {headerSearch}/>
				<Component { ...matchProps } />
				{!hideFooter && <Footer/>}
			</div>
		) }/>
	)
};

export default AppLayout
