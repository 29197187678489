import React from 'react'
import PropTypes from "prop-types";

const IconLocation = props => (
	<svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 26 26" fill="none" {...props}>
		<g opacity={0.68} stroke={props.color} strokeWidth={2} strokeMiterlimit={10}>
			<path d="M13 22.534a9.533 9.533 0 100-19.067 9.533 9.533 0 000 19.067z" />
			<path
				d="M13 1.733V5.2M13 20.8v3.466M24.267 13H20.8M5.2 13H1.733"
				strokeLinecap="round"
			/>
		</g>
	</svg>
)

IconLocation.propTypes = {
	size: PropTypes.number,
	color: PropTypes.string,
}

IconLocation.defaultProps = {
	size: 16,
	color: '#000000'
}

export default IconLocation
