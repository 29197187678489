import React from 'react'
import './dialog-list.scss'

const ChatDialogsList = props => {
	return (
		<div className="dialog-list">
			<header className="dialog-list__header">
				<h4>Your chats</h4>
			</header>
			<div className="dialog-list__content">
				{props.children}
			</div>
		</div>
	)
}

export default ChatDialogsList