import React, { Fragment } from "react";
import { Form, Field } from "react-final-form"
import { connect } from "react-redux"

import BaseButton from "../../components/Button";
import { IconFacebook } from "../../components/ProjectIcons";
import { TextFieldAdapter } from "../../components/TextField";

import "./style.scss"
import {signIn, signUpFacebook} from "../../actions/me";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import {Link} from "react-router-dom";


class SignInPage extends React.Component {


	onSubmit = async values => {
		const { dispatch } = this.props;
		try {
			await dispatch(signIn(values))
		} catch(e) {
			console.error(e);
			return new Error(e);
		}
	};

	facebookResponse = (response) => {
		const {dispatch} = this.props;
		console.log('resp', response);
		dispatch(signUpFacebook(response));
	}

	render() {
		return (
			<Fragment>
				<h1 className='text-white'>Log in</h1>
				{/*<BaseButton icon={ IconFacebook } size="block" color="facebook">Log in with Facebook</BaseButton>*/}
				<FacebookLogin
					appId={process.env.REACT_APP_FACEBOOK_ID}
					fields="name,email,picture"
					callback={this.facebookResponse}
					render={renderProps => (
						<BaseButton icon={ IconFacebook } size="block" color="facebook" disabled={renderProps.isDisabled} onClick={renderProps.onClick}>Log in with Facebook</BaseButton>
					)}
				/>
				<p className='text-white opacity-8'>or sign up with your email address</p>

				{/* Form */ }
				<Form
					onSubmit={ this.onSubmit }
					render={ ({ handleSubmit, pristine, invalid }) => (
						<form onSubmit={ handleSubmit } className={ "signin-page__form" }>
							<div className={ "signin-page__form__text-field" }>
								<Field
									name="email"
									placeholder={ "Email" }
									fullWidth
									component={ TextFieldAdapter }/>
							</div>
							<div className={ "signin-page__form__text-field" }>
								<Field
									type="password"
									name="password"
									placeholder={ "Password" }
									fullWidth
									component={ TextFieldAdapter }/>
									<Link to="/sign/forgot" className="signup-page__form__field-link">
										Forgot password?
									</Link>
							</div>
							<BaseButton type="submit" size='block'>Log in</BaseButton>
						</form>
					) }
				/>

				<footer className="signin-page__footer">
					<span className="text-white">Don’t have an account? &#8195;</span>
					<BaseButton size='small' variant="outlined" color="white" to={ "/sign/register" }>Sign
						Up</BaseButton>
				</footer>
			</Fragment>
		);
	}
}

export default connect(({ me }) => ({ me }))(SignInPage);
