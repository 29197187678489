import React from 'react';

import './main.scss';

const Preloader = ({className = "", text="SEARCHING..."}) => (
    <div className={`preloader ${className}`}>
        <div className="sk-three-bounce">
            <div className="sk-child sk-bounce-1"></div>
            <div className="sk-child sk-bounce-2"></div>
            <div className="sk-child sk-bounce-3"></div>
        </div>
        {'\u00A0'}{text}
    </div>
)

export default Preloader
