import React, {Fragment} from 'react'
import {Link} from 'react-router-dom'
import {connect, useDispatch, useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import logo from '../../images/logo.png'
import BaseButton from '../Button'
import {logout} from "../../actions/me";
import './style.scss'

import UserAvatar from '../UserAvatar'
import Dropdown from '../Dropdown'
import useAuthorized from '../../utils/useAuthorized'
import {BurgerButton} from "../NavDrawer";

const Header = props => {
    const isAuthorized = useAuthorized('cola');
    const dispatch = useDispatch();
    const new_messages_count = useSelector(({notifications = []}) => {
        return notifications.filter(notification => notification.type === "new_message").length
    });
    // Аватарка пользователя с дропдауном, если авторизован
    const viewIsAuthorized = (
        <Fragment>
            <Dropdown
                renderButton={toggleDropdown => (
                    <div className="header__dropdown-button" onClick={toggleDropdown}>
                        <UserAvatar src={props.me.avatar}/>
                        <i className="fa fa-angle-down header__user-section__dropdown-icon" aria-hidden="true"/>
                    </div>
                )}>
                {({toggleDropdown}) => (
                    <div className="header__dropdown-content">
                        <div className="header__dropdown-content-group">
                            <ul>
                                <li>
                                    <Link onClick={toggleDropdown} to={'/liked'}>Liked</Link>{' '}
                                    <span>
									<i className="fa fa-heart-o"
                                       aria-hidden="true"/> {(props.me.liked && props.me.liked.length) ? props.me.liked.length : ""}
								</span>
                                </li>
                                <li>
                                    <Link onClick={toggleDropdown} to={'/visited-events'}>Visited</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="header__dropdown-content-group">
                            <ul>
                                <li>
                                    <Link onClick={toggleDropdown} to={'/new-event'}>Organise event</Link>
                                </li>
                                <li>
                                    <Link onClick={toggleDropdown} to={'/manage-events'}>Manage events</Link>
                                </li>
                            </ul>
                        </div>
                        <ul>
                            {/*<li>*/}
                            {/*<Link onClick={toggleDropdown} to={'/'}>Profile</Link>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*<Link onClick={toggleDropdown} to={'/'}>Settings</Link>*/}
                            {/*</li>*/}
                            <li>
                                <a onClick={() => {
                                    dispatch(logout());
                                    toggleDropdown();
                                }}>Log Out</a>
                            </li>
                        </ul>
                    </div>
                )}
            </Dropdown>
        </Fragment>
    )

    // Ссылки для авторизации, если пользователь не авторизован
    const viewIsNotAuthorized = (
        <Fragment>
            <li>
                <Link to={'/sign'}>Login</Link>
            </li>
            <li>
                <BaseButton to={'/sign/register'} color="primary">
                    Sign up
                </BaseButton>
            </li>
        </Fragment>
    );

    return (
        <header className={classNames('header', `variant-${props.color}`)}>
            <Link to={'/'}>
                <img className="header__logo" src={logo} alt="Trimate"/>
            </Link>
            {props.headerSearch && (
                <div className="header__search">
                    {props.headerSearch ? props.headerSearch : null}
                </div>
            )}
            <nav className="header__nav">
                <BurgerButton className="header__nav-burger"/>
                <ul className="header__nav-list desktop">
                    <li className="header__nav-item">
                        <a href="https://blog.trimate.pro">Blog</a>
                    </li>
                    {isAuthorized && (
                        <Fragment>
                            <li className="header__nav-item">
                                <Link to={'/new-event'}>Organise Session</Link>
                            </li>
                            <li className="header__nav-item">
                                <Link to={'/chat'}>
                                    {new_messages_count !== 0 &&
                                    <span className="header__nav-notifications">{new_messages_count}</span>}
                                    Messages
                                </Link>
                            </li>
                        </Fragment>
                    )}
                    {isAuthorized ? viewIsAuthorized : viewIsNotAuthorized}
                </ul>
            </nav>
        </header>
    )
}

Header.propTypes = {
    color: PropTypes.oneOf(['transparent', 'primary']),
};

Header.defaultProps = {
    color: 'transparent',
};

export default connect(({me}) => ({me}))(Header)
