import React, {Fragment, useState} from "react";
import Modal from "../../components/Modal";
import StarRatings from 'react-star-ratings';
import {Form, Field} from "react-final-form";
import {FormTextAreaAdapter} from "../../components/FormTextArea";
import "./main.scss";
import BaseButton from "../Button";
import {sendReview} from "../../actions/places";
import {useDispatch} from "react-redux";
import Preloader from "../Preloader";
import { toast } from 'react-toastify'


export default WrappedComponent => ({...props}) => {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState({
        texts: {},
        reviewed: {}
    });
    const [loading, setLoading] = useState(false);
    const openModal = (options) => {
        setOptions(options);
        setOpen(true);
    };
    const dispatch = useDispatch();
    const closeModal = () => setOpen(false);
    const handleSubmit = async (review) => {
        setLoading(true);
        let {status} = await dispatch(sendReview({review, place: options.reviewed._id}));
        setLoading(false);
        if(status === "ok"){
            toast.success("Successfully added your review");
            setOpen(false);
            if(options.onReview){
                options.onReview();
            }
        }
    };
    return (
        <Fragment>
            <Modal isOpen={open} onRequestClose={closeModal}>
                <Form
                    initialValues = {{
                        rating: 3
                    }}
                    onSubmit={handleSubmit}
                    render={({handleSubmit}) => (
                        <form onSubmit={handleSubmit} className="review-modal">
                            {!loading ? (
                                <Fragment>
                                    <h1 className="review-modal__title">{options.texts.title}</h1>
                                    <h3 className="review-modal__desc">{options.texts.description}</h3>
                                    <div className="review-modal__row">
                                        <Field name="rating">
                                            {({input}) => (
                                                <StarRatings
                                                    changeRating = {(rating) => input.onChange(rating)}
                                                    rating={input.value}
                                                    starDimension={40}
                                                    starRatedColor="#009AF0"
                                                    starHoverColor="#66c2f6"
                                                    starSpacing={2}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                    <div className="review-modal__row">
                                        <Field
                                            component = {FormTextAreaAdapter}
                                            name="review"
                                            fullWidth
                                            variant = "outlined"
                                            className = "review-modal__text"
                                            minRows = {8}
                                            placeholder="Write review here..."
                                        />
                                    </div>
                                    <div className="review-modal__row">
                                        <BaseButton type="submit">Add review</BaseButton>
                                    </div>

                                </Fragment>
                            ) : (
                                <Preloader text="Sending review..."/>
                            )}
                        </form>)
                    }
                />
            </Modal>
            <WrappedComponent openReviewModal = {openModal} closeReviewModal = {closeModal} {...props}/>
        </Fragment>
    );
};
