import React, { Fragment } from "react";
import BaseButton from "../../components/Button";
import { Form, Field } from "react-final-form"
import "./style.scss"
import {connect} from "react-redux";
import {resetPassword} from "../../actions/me";
import { TextFieldAdapter } from "../../components/TextField";
import {toast} from "react-toastify";
import qs from "qs";
import { push } from "connected-react-router";

class ResetPassword extends React.Component {
	onSubmit = async (values) => {
		if(values.password === values.repeat_password) {
			const {dispatch} = this.props;
			const {code, user} = qs.parse(window.location.search.replace("?",""));
			const {status} = await dispatch(resetPassword({code, user, password: values.password}));
			if(status === "ok"){
				toast.success("Password successfully changed!");
				dispatch(push("/sign"))
			}
		} else {
			toast.error("Passwords not matching");
		}
	};

	render() {
		return (
			<Fragment>
					<h1 className='text-white'>Reset password</h1>
					<p className='text-white opacity-8'>Set new password for your account.</p>
					{/* Form */ }
					<Form
						onSubmit={ this.onSubmit }
						render={ ({ handleSubmit, pristine, invalid }) => (
							<form onSubmit={ handleSubmit } className={ "forgot-page__form" }>
								<div className={ "forgot-page__form__text-field" }>
									<Field
										type="password"
										name="password"
										placeholder={ "Password" }
										fullWidth
										required
										component={ TextFieldAdapter }/>
								</div>
								<div className={ "forgot-page__form__text-field" }>
									<Field
										type="password"
										name="repeat_password"
										placeholder={ "Repeat password" }
										fullWidth
										required
										component={ TextFieldAdapter }/>
								</div>
								<BaseButton type="submit" size='block'>
									Reset password
								</BaseButton>
							</form>
						) }
					/>

					<footer className="forgot-page__footer">
						<BaseButton size='small' variant="outlined" color="white" to={'/sign'}>Log in</BaseButton>
						<BaseButton size='small' variant="outlined" color="white" to={'/sign/register'}>Sign Up</BaseButton>
					</footer>
			</Fragment>
		);
	}
}

export default connect(null)(ResetPassword);
