import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import PropTypes from "prop-types";
import PlacesList from "../../components/PlacesList";
import {getLikedPlaces} from "../../actions/me";
import "./main.scss"

const ManageEvents = (props) => {
    const dispatch = useDispatch();

    const getEvents = async (params) => {
        const {places} = await dispatch(getLikedPlaces(params));
        return places;
    };

    return (
        <div className="visited-event">
            <PlacesList
                title = "Liked places"
                getPlaces={getEvents}
            />
        </div>
    );
};

ManageEvents.propTypes = {

};

export default ManageEvents;
