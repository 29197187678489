import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import './style.scss'

const EventAvatar = props => {

	const className = classNames({
		"event-avatar": true,
		[`size-${ props.size }`]: props.size,
	});

	return (
		<div className={className} style={{backgroundImage: `url(${props.src})`}}>
			{/*<img src={ props.src } alt="Trimate user"/>*/}
		</div>
	);
};

EventAvatar.propTypes = {
	src: PropTypes.string.isRequired,
	size: PropTypes.oneOf(["small", "normal", "big"]),
};

EventAvatar.defaultProps = {
	size: "normal",
};

export default EventAvatar;
