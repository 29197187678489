import React from 'react'
import PropTypes from "prop-types";

const IconCamera = props => (
	<svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 45 36" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M22.5 9.334c-6.214 0-11.25 4.737-11.25 10.583 0 5.845 5.036 10.583 11.25 10.583 6.213 0 11.25-4.738 11.25-10.583 0-5.844-5.037-10.583-11.25-10.583zm6.405 15.749c-3.031 3.328-8.357 3.713-11.895.86-3.54-2.852-3.947-7.863-.914-11.19 3.03-3.33 8.357-3.714 11.895-.86 3.537 2.85 3.946 7.863.915 11.19z"
			fill={props.color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M22.5 14.626c-3.106 0-5.624 2.369-5.625 5.29v.003c0 .363.315.66.703.66.388 0 .703-.297.703-.66v-.003c.002-2.19 1.889-3.967 4.219-3.967.388 0 .703-.297.703-.662 0-.365-.315-.661-.703-.661z"
			fill={props.color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M41.473 8.066l-6.095-.957-1.93-4.542C32.802 1.052 31.264.073 29.53.073H15.47c-1.733 0-3.271.979-3.918 2.495L9.62 7.109l-6.093.957C1.484 8.385 0 10.03 0 11.979v19.843c0 2.188 1.893 3.968 4.219 3.968H40.78c2.326 0 4.219-1.78 4.219-3.968V11.979c0-1.949-1.484-3.594-3.527-3.913zm.715 23.756c0 .731-.63 1.322-1.407 1.322H4.22c-.778 0-1.407-.59-1.407-1.322V11.979c0-.647.498-1.199 1.176-1.305l7.656-1.2 2.518-5.923c.215-.502.732-.832 1.307-.832H29.53c.574 0 1.09.33 1.305.832l2.519 5.924 7.657 1.2c.678.105 1.175.657 1.175 1.304v19.843z"
			fill={props.color}
		/>
	</svg>
)

IconCamera.propTypes = {
	size: PropTypes.number,
	color: PropTypes.string,
}

IconCamera.defaultProps = {
	size: 16,
	color: '#000000'
}

export default IconCamera
