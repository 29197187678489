export const required = value => (value ? undefined : "Required");
export const mustBeNumber = value => (isNaN(value) ? "Must be a number" : undefined);
export const minValue = min => value => {
	console.log("min => ",min,"  value => ",value);
	return isNaN(value) || value >= min ? undefined : `Should be greater than ${ min }`;
};

export const maxValue = max => value =>{
	console.log("max => ",max,"  value => ",value);
	return isNaN(value) || value <= max ? undefined : `Should be lesst than ${ max }`;
}


export const composeValidators = (...validators) => value =>{
	if(validators){
		validators.reduce((error, validator) => error || validator(value), undefined)
	}else{
		console.log("no validators to compose!");
		return null;
	}
}

