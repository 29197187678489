import React from 'react'
import PropTypes from 'prop-types'

const IconClose = props => (
  <svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 44 44" fill="none" {...props}>
	  <path
		  d="M1.306 3.932A1.848 1.848 0 013.92 1.318l16.666 16.655a2 2 0 002.828 0L40.069 1.307a1.848 1.848 0 012.614 2.612L26.027 20.584a2 2 0 00.001 2.829l16.666 16.654a1.848 1.848 0 11-2.613 2.615L23.415 26.027a2 2 0 00-2.828 0L3.93 42.694a1.848 1.848 0 01-2.613-2.613l16.656-16.665a2 2 0 00-.001-2.828L1.306 3.932z"
		  fill={props.color}
		  fillOpacity={0.8}
	  />
  </svg>
)

IconClose.propTypes = {
	size: PropTypes.number,
	color: PropTypes.string,
}

IconClose.defaultProps = {
	size: 16,
	color: '#000000',
}

export default IconClose
