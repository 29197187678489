import React from 'react'
import PropTypes from 'prop-types'

const IconFacebook = props => (
  <svg className={props.className} width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 30 30" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.173 27.357a2.47 2.47 0 002.471 2.47h24.713a2.47 2.47 0 002.47-2.47V2.644a2.47 2.47 0 00-2.47-2.471H2.644A2.47 2.47 0 00.173 2.644v24.713zm15.445-1.236v-8.65c0-.682-.553-1.235-1.236-1.235A1.236 1.236 0 0113.148 15v-1.235c0-.683.553-1.236 1.236-1.236a1.25 1.25 0 001.242-1.238c.06-4.705.67-5.447 6.168-5.545a1.834 1.834 0 011.857 1.84c0 1.024-.832 1.827-1.853 1.893-1.373.09-1.744.37-1.836 1.197-.113 1.018.812 1.853 1.835 1.853a1.853 1.853 0 110 3.707c-1.023 0-1.853.83-1.853 1.854v5.869a2.162 2.162 0 01-2.163 2.162h-2.162z"
      fill={props.fill}
    />
  </svg>
)

IconFacebook.propTypes = {
	size: PropTypes.number,
    fill: PropTypes.string,
    className: PropTypes.string
};

IconFacebook.defaultProps = {
	size: 16,
    fill: "#fff",
    className: ""
};

export default IconFacebook
