import React, { useState, useEffect, Fragment } from "react";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";

import { IconCamera } from "../ProjectIcons";
import BaseButton from "../Button";
import requester from "../../requester";

import "./style.scss";

//Hook variant

export const useFileUpload = ({onUpload, value=""} = {}) => {
	const [files, setFiles] = useState([]);
	const [progress, setProgress] = useState(0);

	useEffect(() => {
		if(value){
			setFiles([value])
		}
	}, [value]);

	const { getRootProps, getInputProps } = useDropzone({
		accept: "image/*",
		multiple: true,
		onDrop: async acceptedFiles => {
			let data = new FormData();
			acceptedFiles.forEach((file) => {
				data.append("file", file);
			});
			requester
				.post("/upload", data, {
					onUploadProgress: function(progressEvent) {
						let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
						setProgress(percentCompleted);
					},
				})
				.then(request => {
					console.log("request", request);
					setFiles([...files, ...request.data.files]);
					setProgress(0);
					if(onUpload){
						onUpload([...files, ...request.data.files]);
					}
				})
				.catch(err => console.log(err));
		},
	});
	const removeFile = (file_to_remove) => {
		if(file_to_remove) {
			setFiles(files.filter(file => file !== file_to_remove));
		} else {
			setFiles([]);
		}
	};
	return {removeFile, getRootProps, getInputProps, files, progress};
};

export const FileUpload = ({onUpload, value="", ...props}) => {
	const [files, setFiles] = useState([]);
	useEffect(() => {
		if(value){
			setFiles([value])
		}
	}, [value]);
	// const [upload, setUpload] = useState(0);
	const { getRootProps, getInputProps } = useDropzone({
		accept: "image/*",
		multiple: false,
		onDrop: async acceptedFiles => {
			let data = new FormData();
			acceptedFiles.forEach((file) => {
				data.append("file", file);
			});
			requester
				.post("/upload", data, {
					onUploadProgress: function(progressEvent) {
						// let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
						// setUpload(percentCompleted);
					},
				})
				.then(request => {
					console.log("request", request);
					setFiles(request.data.files);
					if(onUpload){
						onUpload(request.data.files);
					}
				})
				.catch(err => console.log(err));
		},
	});
	const removeFile = () => setFiles([]);
	return props.children({removeFile, getRootProps, getInputProps, files});
};

export const ProfileUpload = ({...props}) => {
	return (
		<FileUpload {...props}>
			{({removeFile, getRootProps, getInputProps, files}) => {
				return (
					<Fragment>
						<div className={ "form-upload__header form-upload__header_position_center"}>
							<p>
								{ props.required && <span className={ "required" }>*</span> }
							</p>
							{ files.length > 0 && (
								<BaseButton
									size="small"
									variant="onlytext"
									color="primary"
									onClick={ removeFile }
								>
									<i className="fa fa-trash" aria-hidden="true"/>
									&nbsp;Remove
								</BaseButton>
							) }
						</div>
						<div className={ "form-upload form-upload_shape_round" }>
							<div { ...getRootProps({ className: "form-upload__container" }) }>
								<input { ...getInputProps() } />
								{files && files.length ? (
									<img className="form-upload__thumb" src={ files[0] } alt=""/>
								) : <IconCamera size={ 80 } color={ "#009AF0" }/>}
							</div>
						</div>
					</Fragment>
				)
			}}
		</FileUpload>
	)
};
const FormUpload = ({onUpload, value="", ...props} = {}) => {
	const [file, setFiles] = useState([]);
	useEffect(() => {
		setFiles([value])
	}, [value]);
	// const [upload, setUpload] = useState(0);
	const { getRootProps, getInputProps } = useDropzone({
		accept: "image/*",
		multiple: false,
		onDrop: async acceptedFiles => {
			let data = new FormData();
			data.append("file", acceptedFiles[0]);
			requester
				.post("/upload", data, {
					onUploadProgress: function(progressEvent) {
						// let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
						// setUpload(percentCompleted);
					},
				})
				.then(request => {
					console.log("request", request);
					setFiles(request.data.files[0]);
					onUpload(request.data.files[0]);
				})
				.catch(err => console.log(err));
		},
	});

	const removeFile = () => {
		setFiles([]);
		if(onUpload){
			onUpload(null);
		}
	};

	// useEffect(() => {
	// 	onUpload(file)
	// }, [file]);

	return (
		<Fragment>
			<div className={ "form-upload__header" }>
				<p>
					{ props.label }
					{ props.required && <span className={ "required" }>*</span> }
				</p>
				{ file.length > 0 && (
					<BaseButton
						size="small"
						variant="onlytext"
						color="primary"
						onClick={ removeFile }
					>
						<i className="fa fa-trash" aria-hidden="true"/>
						&nbsp;Remove
					</BaseButton>
				) }
			</div>
			<div className={ "form-upload" }>
				<div { ...getRootProps({ className: "form-upload__container" }) }>
					<input { ...getInputProps() } />
					<img className="form-upload__thumb" src={ file } alt=""/>
					<IconCamera size={ 45 } color={ "#009AF0" }/>
					<h2 className="form-upload__title">Add event image</h2>
					<p className="form-upload__text">
						Choose a compelling image that brings your event to life.
					</p>
					<p className="form-upload__info">
						We recommend using at least 2160 x 1080px (2:1 rario) image.
					</p>
				</div>
			</div>
			{ props.meta.error && props.meta.touched && <span className="form-field__error">{ props.meta.error }</span> }
		</Fragment>
	);
};

FormUpload.propTypes = {
	label: PropTypes.string,
	required: PropTypes.bool,
	onUpload: PropTypes.func,
	meta: PropTypes.object
};

export default FormUpload;
