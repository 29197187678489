import React from 'react'
import PropTypes from "prop-types";

const IconTimes= props => (
    <svg {...props} className={props.className} width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.783234 2.63507C0.373251 2.22535 0.37285 1.56091 0.782337 1.1507C1.19215 0.740162 1.85722 0.739757 2.26753 1.14979L11.126 10.0024C11.9073 10.7831 13.1735 10.7827 13.9543 10.0015L22.8073 1.1439C23.2174 0.733604 23.8825 0.733604 24.2926 1.1439C24.7024 1.5539 24.7024 2.2184 24.2926 2.62839L15.4398 11.4856C14.6589 12.2669 14.6593 13.5333 15.4406 14.3141L24.2987 23.1664C24.7087 23.5762 24.7091 24.2407 24.2996 24.651C23.8897 25.0615 23.2246 25.0619 22.8142 24.6518L13.9559 15.7992C13.1746 15.0184 11.9084 15.0188 11.1276 15.8L2.27419 24.658C1.86416 25.0682 1.19915 25.0682 0.789127 24.658C0.379404 24.248 0.379404 23.5836 0.789127 23.1737L9.64218 14.316C10.4231 13.5347 10.4227 12.2683 9.64135 11.4874L0.783234 2.63507Z" fill="currentColor"/>
    </svg>
);

IconTimes.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
    className: PropTypes.string
}

IconTimes.defaultProps = {
    size: 16,
    color: '#000000',
    className: ""
}

export default IconTimes



