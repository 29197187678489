import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {IconUser} from "../../components/ProjectIcons";

import './style.scss'

const UserAvatar = props => {

	const className = classNames({
		"user-avatar": true,
		[`size-${ props.size }`]: props.size,
	});
	return (
		props.src ? (
			<img className={className} src={ props.src } />
		) : (
			<div className={className}><IconUser className="user-avatar__icon" size={47}/></div>
		)
	);
};

UserAvatar.propTypes = {
	src: PropTypes.string,
	size: PropTypes.oneOf(["small", "normal", "big"]),
};

UserAvatar.defaultProps = {
	size: "normal",
};

export default UserAvatar;
