import requester from '../requester'
import { toast } from 'react-toastify'

export const getChats = () => async dispatch => {
	try {
		const res = await requester.get("/chats");
		dispatch({
			type: "SET_CHATS",
			payload: res.data.chats,
		});
		return res.data.chats;
	} catch(e) {
		toast.error(e.response.data.code);
		console.error("getChats action error", e.response);
		return new Error(e.response);
	}
}

export const getChat = (id) => async dispatch => {
	try {
		const res = await requester.get(`/chats/${id}`)
		dispatch({
			type: 'SET_CHAT',
			payload: res.data.chat,
		})
		return res.data.chat
	} catch(e) {
		toast.error(e.response.data.code);
		console.error("getChat action error", e.response);
		return new Error(e.response);
	}
}

export const sendMessage = (chat_id, msg) => async dispatch => {
	try {
		const res = await requester.post(`/chats/${chat_id}/message`, msg);
		console.log('res', res.data)
		dispatch({
			type: 'ADD_MESSAGES',
			payload: res.data.message
		})
		return res.data
	} catch(e) {
		toast.error(e.response.data.code);
		console.error("sendMessage action error", e.response);
		return new Error(e.response);
	}
}



export const readMessages = (chat_id) => async dispatch => {
	try {
		const res = await requester.post(`/chats/${chat_id}/read`);
		dispatch({
			type: 'SET_NOTIFICATIONS',
			notifications: res.data.notifications
		});
		return res.data
	} catch(e) {
		console.error("sendMessage action error", e);
		toast.error(e.response.data.code);
		return new Error(e.response);
	}
}
