import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import IconButton from '../../IconButton'

import './text-field.scss'
import {useFileUpload} from "../../FormUpload";
import {IconTimes} from "../../ProjectIcons";
import {Line} from 'rc-progress';
import TextareaAutosize from "react-textarea-autosize";

const ChatTextField = props => {
	const [message, setMessage] = useState("");

	const {removeFile, getRootProps, getInputProps, files, progress} = useFileUpload();

	useEffect(() => {
		if(props.onChange) {
			props.onChange({message, attachments: files})
		}
	}, [message, files]);

	const sendMessage = () => {
		if(props.onSubmit && (message || files.length)){
			props.onSubmit({message, attachments: files});
			setMessage("");
			removeFile();
		}
	};

	const keyPress = (e) => {

		if(e.keyCode === 13 && props.onSubmit) {
			sendMessage();
		}
	};


	return (
		<div className="c-text-field__wrap">
			<div className="c-text-field__files">
				{(progress !== 0) && <Line className="c-text-field__files-progress" percent={progress} strokeColor="#009AF0" strokeLinecap="square"/>}
				{files.map((file) => (
					<div className="c-text-field__file" style = {{backgroundImage: `url(${file})`}}>
						<div className="c-text-field__file-remove" onClick={() => removeFile(file)}>
							<IconTimes size={10}/>
						</div>
					</div>
				))}
			</div>
			<div className="c-text-field">
				<TextareaAutosize
					type="text"
					className="c-text-field__input"
					placeholder="Type your message..."
					value={ message }
					onChange={ e => setMessage(e.target.value) }
					onKeyDown={ keyPress }
				/>
				<div className="c-text-field__attach-button" {...getRootProps()}>
					<IconButton color="grey" variant="text">
						<input { ...getInputProps() } />
						<i className="fa fa-paperclip" aria-hidden="true"/>
					</IconButton>
				</div>
				<div className="c-text-field__send-button" onClick={sendMessage}>
					<IconButton variant="contained" color="primary" size="small">
						<i className="fa fa-paper-plane" aria-hidden="true"/>
					</IconButton>
				</div>
			</div>
		</div>
	)
}

ChatTextField.propTypes = {
	onChange: PropTypes.func,
	onSubmit: PropTypes.func
}

export default ChatTextField
