import React from "react";
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './style.scss'

const IconButton = props => {

	const buttonProps = {...props};
	delete buttonProps.withoutFeedback;

	const buttonClasses = classNames({
		'icon-button': true,
		'without-feedback': props.withoutFeedback,
		[`variant-${props.variant}`]: props.variant,
		[`color-${props.color}`]: props.color,
		[`size-${props.size}`]: props.size,
		[props.className]: true
	});

	return (
		<button {...buttonProps} className={buttonClasses}>
			{props.children}
		</button>
	);
};

IconButton.propTypes = {
	withoutFeedback: PropTypes.bool,
	color: PropTypes.oneOf(['primary', 'grey', 'default']),
	variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
	size: PropTypes.oneOf(['small', 'normal', 'big']),
	className: PropTypes.string
}

IconButton.defaultProps = {
	withoutFeedback: false,
	color: 'default',
	variant: 'text',
	size: 'normal',
	className:""
}

export default IconButton;
