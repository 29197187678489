import React from "react";
import "./main.scss";
import {EmailShareButton} from "react-share";
import {IconEmail} from "../ProjectIcons";

export default () => (
    <EmailShareButton className="share-option" url={window.location.href}>
        <IconEmail size={30} className="share-option__icon"/>
        <span className="share-option__name">Email</span>
    </EmailShareButton>
);
