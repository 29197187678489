import React from "react";
import PropTypes from "prop-types";
import {Link} from 'react-router-dom'
import classNames from 'classnames'

import './style.scss'

const BaseButton = ({icon: IconComponent , ...props}) => {

	const buttonClasses = classNames({
		'btn': true,
		[`btn-${props.color}`]: props.color,
		[`btn__size-${props.size}`]: props.size,
		[`btn-${props.color}__variant-${props.variant}`]: props.variant,
		'btn-with-icon': IconComponent,
		[props.className]: props.className,
		...(props.round ? {"btn_shape_round": true} : {})
	});
	if(props.to) {
		return <Link to={props.to} {...props}  className={buttonClasses}>{props.children}</Link>
	}

	return (
		<button {...props} className={buttonClasses} onClick={props.onClick}>
			{IconComponent && <span className="btn__icon"><IconComponent size={24}/></span>}
			{props.children}
		</button>
	);
};

BaseButton.propTypes = {
	color: PropTypes.oneOf(['white', 'primary', 'facebook']),
	size: PropTypes.oneOf(['small', 'normal', 'large', 'block']),
	variant: PropTypes.oneOf(['contained', 'outlined', 'onlytext']),
	round: PropTypes.bool,
	to: PropTypes.string,
	icon: PropTypes.elementType,
	className: PropTypes.string,
};

BaseButton.defaultProps = {
	color: 'primary',
	size: 'normal',
	variant: 'contained',
	className: ""
};


export default BaseButton;
