import React from "react";
import { Route } from "react-router";
import logo from "../../../images/logo.png"
import { IconClose } from "../../../components/ProjectIcons";
import IconButton from "../../../components/IconButton";
import {Link} from "react-router-dom";
import "./style.scss"

const SignLayout = ({ component: Component, ...rest }) => {
	return (
		<Route { ...rest } render={ matchProps => (
			<div className="signpage">

				<div className="close-button">
				</div>

				{/* Header */ }
				<header className="signpage__header">
					<Link to="/">
						<img className="signpage__header__logo" src={ logo } alt={ "Trimate" }/>
					</Link>
				</header>

				{/* Content */ }
				<div className="signpage__content">
					<IconButton
						className = "signpage__content-close"
						withoutFeedback={ true }
						onClick={ () => matchProps.history.push("/")}
					>
						<IconClose color="#FFFFFF" size={ 33 }/>
					</IconButton>
					<Component { ...matchProps } />
				</div>
			</div>
		)} />
	);
};


export default SignLayout;
