import React from "react";
import "./main.scss";
import {WhatsappShareButton, WhatsappIcon} from "react-share";

export default () => (
    <WhatsappShareButton className="share-option" url={window.location.href}>
        <div className="share-option__icon">
            <WhatsappIcon round size={30} fill="#3B5998"/>
        </div>
        <span className="share-option__name">Whatsapp</span>
    </WhatsappShareButton>
);
