import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import UserAvatar from '../../UserAvatar'
import withImagesGalleryModal from "../../ImagesGallery";
import "./message.scss"

const ChatMessage = props => {
	return (
		<div className={classNames({"message": true, "message__my": props.myMessage})}>
			<UserAvatar src={props.from.avatar} />
			{props.msg && (
				<div className="message__text">
					{props.msg.message}
					{(props.msg.attachments && props.msg.attachments.length) ? (
						<ul className="message__attachments">
							{props.msg.attachments.map((attachment, key) => (
								<li className="message__attachment" onClick={() => {
									props.openImagesModal({
										images: props.msg.attachments.map((attach) => ({src: attach, caption: props.msg.message})),
										currentIndex: key
									});
								}}>
									<div className="message__attachment-img" style={{backgroundImage: `url(${attachment})`}}/>
								</li>
							))}
						</ul>
					): null}
				</div>
			)}
		</div>
	)
}

ChatMessage.propTypes = {
	myMessage: PropTypes.bool,
	from: PropTypes.object,
	msg: PropTypes.object
};

ChatMessage.defaultProps = {
	from: {},
	msg: {}
};

export default ChatMessage
