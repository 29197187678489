import React from "react";
import classNames from 'classnames'

import './style.scss'

const TextField = ({value, placeholder, fullWidth, ...rest}) => {

    const containerClassNames = classNames({
        "text-field": true,
        "text-field__full-width": fullWidth
    });

    return (
        <div className={containerClassNames}>
            <input className={'text-field__input'} value={value}  placeholder={placeholder} {...rest }/>
        </div>
    )
};

const TextFieldAdapter = ({ input, meta, ...rest }) => (
    <TextField
        {...rest}
        {...input}
        onChange={(e) => {
            input.onChange(e.target.value)
        }}
    />
)

export {
    TextFieldAdapter
}

export default TextField;
