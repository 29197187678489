export default {
	places: [],
	place: {},
	me: {},
	chats: [],
	chat: {},
	notifications: [],
	navigation: {
		drawer: false
	}
}
