import React, { Component, Fragment } from "react";
import moment from "moment";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import  {compose} from "redux";
import Preloader from "../../components/Preloader";
import Map from "../../components/Map";
import {IconShare, IconLike, IconLiked, IconCheck} from "../../components/ProjectIcons";
import "./event.scss";
import { getPlace, getReviews } from "../../actions/places";
import { likePlace, markGoing } from "../../actions/me";
import withShareModal from "../../components/ShareModal";
import _ from"underscore"
import Button from "../../components/Button";
import SlideButton from "../../components/SlideBtn";
import Reviews from "../../components/Reviews";

library.add(faStar);
moment.locale("en");

class Event extends Component {
	state = {
		place: {},
		fetching: false,
		rating: 0,
	};

	async componentDidMount() {
		const { id } = this.props.match.params;
		const { dispatch } = this.props;
		console.log('did mount', id);
		try {
			this.setState({fetching: true});
			await dispatch(getPlace(id));
			this.setState({fetching: false})
		} catch(e) {
			console.error('componentDidMount error', e)
		}
	}

	likePlace= () => {
		const {dispatch, place} = this.props;
		dispatch(likePlace(place._id));
	};

	markGoing = () => {
		const {dispatch, place} = this.props;
		dispatch(markGoing(place._id));
	};
	fetchReviews = async (params) => {
		const data = await this.props.dispatch(getReviews(params));
		if(data.reviews.rating && !this.state.rating){
			this.setState({rating: data.reviews.rating});
		}
		return data;
	};

	render() {
		const { place, openShareModal, me, dispatch} = this.props;
		// let { worktime, photo, name, price, priceSymbol = "£", adress, distance, outdoor, rate, dateStart, dateEnd, activities, description, details, phone } = place;

		const placesActivities = [
			{ name: "running", color: "green" },
			{ name: "swimming", color: "blue" },
			{ name: "cycling", color: "pink" },
		];

		const CheckIcon = () => <IconCheck className="event-main__going-icon"/>;
		return (
			<Fragment>
				<div className="event">
					{ !_.isEmpty(place) ? (
						<Fragment>
							<div className="event__photo" style={ { backgroundImage: `url(${ place.photo })` } }>
								<div className="container event-info">
									<h1 className="event__name">{ place.name }</h1>
									<div className="event-info__control">
												<Button
													size="small"
													className="event-info__control-btn"
													variant = "outlined"
													round={true}
													color="white"
													icon = {IconShare}
													onClick = {openShareModal}
												>
													Share
												</Button>
											{me && (
												<Button
													size="small"
													className="event-info__control-btn"
													variant = "outlined"
													round={true}
													color="white"
													icon={me.liked && me.liked.includes(place._id) ? IconLiked : IconLike}
													onClick = {this.likePlace}
												>
													{me.liked && me.liked.includes(place._id) ? "Saved" : "Save"}
												</Button>
										)}
									</div>
									<div className="event-info__meta">
										<div className="event__activities">
											{ place.activities.map(item => (
												<div key={ item } className={ `event__activities-item color-${ item }` }>
													{ item }
												</div>
											)) }
										</div>
										<div className="event-params">
											{ place.distance ? (
												<div className="event-params__item">
													{ place.distance }
												</div>
											) : null }
											{ place.outdoor ? (
												<div className="event-params__item">
													{ place.outdoor ? "outdoor" : "indoor" }
												</div>
											) : null }
											{ this.state.rating ? (
												<div className="event-params__item event-params__item_rate">
													<FontAwesomeIcon
														style={ {
															color: place.photo ? "#60B6FB" : "#fff",
														} }
														className="event-params__star"
														icon="star"
													/>
													{ this.state.rating }
												</div>
											) : null }
										</div>
									</div>
								</div>
							</div>
							<main className="container event-main">
								<div className="event-main__left">
									<h1 className="event-main__name">{ place.name }</h1>
									<p className="event-main__description">
										{ place.description }
									</p>
									{ place.details ? (
										<Fragment>
											<h2 className="event-main__details-title">
												Details
											</h2>
											<p className="event-main__details">
												{ place.details }
											</p>
										</Fragment>
									) : null }

									{ place.worktime ? (
										<div>
											<h2 className="event-main__details-title">
												Work time:
											</h2>
											{ place.worktime.split("\n").map((text,key) => (
												<p key = {key} className="event-main__worktime">
													{ text }
												</p>
											)) }
										</div>
									) : null }
									<Reviews
										getReviews = {this.fetchReviews}
										place_id = {place._id}
										addReviewDesc={`Tell us about your experience at \n ${place.name}`}
									/>
								</div>
								<div className="event-main__right">
									<div className="event-main__map">
										<Map
											places={ [place] }
											activities={ placesActivities }
											single
										/>
									</div>
									<div className="event-main__right-info">
										{(place.dateStart && place.dateEnd)?(
											<div className="event-main__date event-main__right-item">
											<img
												src={ require("../../images/time.png") }
												alt=""
											/>
												
												<div className="event-main__right-item-text">
													
													{place.dateStart?
														<>
														Starting at:<br/>
														{moment(place.dateStart).format(
															"dddd, MMM, D, YYYY, h:mm A"
														)}
														</>
													:null}

													{(place.dateEnd)?
														<>
														<br/><br/>
														Ending at: <br/>
														{moment(place.dateEnd).format(
															"dddd, MMM, D, YYYY, h:mm A",
														)}
													</>:null}
													
												</div>
												
											</div>
										):null}
										{ place.address ? (
											<div className="event-main__location event-main__right-item">
												<img
													src={ require("../../images/location.png") }
													alt=""
												/>
												<div className="event-main__right-item-text">
													{ place.address }
												</div>
											</div>
										) : null }
										{ place.phone ? (
											<div className="event-main__phone event-main__right-item">
												<img
													src={ require("../../images/phone.png") }
													alt=""
												/>
												<div className="event-main__right-item-text">
													{ place.phone }
												</div>
											</div>
										) : null }

										<div className="event-main__right-item event-main__price">
											<img
												src={ require("../../images/pig.png") }
												alt=""
											/>
											<div className="event-main__right-item-text">
												{ place.price && place.price !== 0
													?  `£${place.price}`
													: "Free" }
											</div>
										</div>
										{/*<Button className="event-main__going-btn">*/}
										{/*<IconCheck className="event-main__going-icon"/> Going*/}
										{/*</Button>*/}
										{place.type === "event" && (
											<div className="event-main__right-item event-main__going">
												{place.participants && place.participants.includes(me._id) ? (
													<SlideButton
														className = "event-main__going-btn"
														config={{
															left: {
																title: "Going",
																icon: CheckIcon,
															},
															right: {
																title: "Cancel",
																color: "red",
															}
														}}
														onClick={this.markGoing}
													/>
												) : (
													<Button className="event-main__going-btn" variant="outlined" onClick = {this.markGoing}>
														I am going
													</Button>
												)}
											</div>
										)}
										{/*worktime?(
										 <div className="event-main__right-item event-main__price">
										 <img src={require('./images/time.png')} alt=""/>
										 Work time: {worktime}
										 </div>
										 ):null*/ }
									</div>
								</div>
							</main>
						</Fragment>
					) : (
						<div className="event__preloader">
							<Preloader noText/>
						</div>
					) }
				</div>
			</Fragment>
		);
	}
}

export default compose(
	connect(({ place, me }) => ({ place, me})),
	withShareModal
)(Event)
