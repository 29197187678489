import React from "react"
import { Route } from "react-router-dom"
import Header from "../../Header";
import './style.scss'
import Footer from "../../Footer";

const HomeLayout = ({ component: Component, ...rest }) => {
	return (
		<Route { ...rest } render={ matchProps => (
			<>
				<div className={'home-layout'}>
					<Header />
					<Component { ...matchProps } />
				</div>

				<h2 className="homepage__subheader">Why use Trimate?</h2>

				<div className="homepage-section">
					<div className="homepage-container">
						<div className="homepage__row">
							<div className="homepage__column">
								<img className="homepage__image" src={require("../../../images/Macbook1.png")} />
							</div>
							<div className="homepage__column">
								<div className="homepage__subsubheader">
									UK triathlon facilities in one place
								</div>
								<p className="homepage__description">
									We've hand picked all the best pools, cycling
									and running tracks as well as gyms and
									other indoor facilities
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="homepage-section blue no-padding-bottom">
					<div className="homepage-container">
						<div className="homepage__row">
							
							<div className="homepage__column">
								<div className="homepage__subsubheader">
									Bored of long training sessions alone?
								</div>
								<p className="homepage__description">
								Find your Trimates to train with or give you that boost when you need it
								</p>
							</div>
							<div className="homepage__column">
								<img className="homepage__image" src={require("../../../images/Macbook2.png")} />
							</div>
						</div>
					</div>
				</div>
				<div className="homepage-section">
					<div className="homepage-container">
						<div className="homepage__row">
							<div className="homepage__column">
								<img className="homepage__image" src={require("../../../images/Macbook3.png")} />
							</div>
							<div className="homepage__column">
								<div className="homepage__subsubheader">
									Host and join training sessions
								</div>
								<p className="homepage__description">
								Find training sessions in your area<br/>
								or organise your own
								</p>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</>
		) }/>
	)
};

export default HomeLayout