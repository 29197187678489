import React from "react";
import "./main.scss";
import {FacebookShareButton} from "react-share";
import {IconFacebook} from "../ProjectIcons";

export default () => (
    <FacebookShareButton className="share-option" url={window.location.href}>
        <IconFacebook size={30} className="share-option__icon" fill="#3B5998"/>
        <span className="share-option__name">Facebook</span>
    </FacebookShareButton>
);
