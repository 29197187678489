import React, {Fragment} from "react";
import Drawer from 'react-motion-drawer';
import {useDispatch, useSelector} from "react-redux";
import classNames from "classnames";
import "./main.scss";
import {IconTimes} from "../ProjectIcons";
import UserAvatar from "../UserAvatar";
import useAuthorized from "../../utils/useAuthorized";
import {Link} from "react-router-dom";
import {logout} from "../../actions/me";
import {push} from "connected-react-router";

const useDrawer = () => {
    const drawer = useSelector(({navigation}) => navigation.drawer);
    const me = useSelector(({me}) => me);
    const isAuthorized = useAuthorized();
    const dispatch = useDispatch();
    const toggleDrawer = () => !drawer ? dispatch({type: "OPEN_DRAWER"}) : dispatch({type: "CLOSE_DRAWER"});
    return({drawer, me, isAuthorized, toggleDrawer});
};

export const BurgerButton = (props) => {
    const {toggleDrawer, drawer} = useDrawer();
    const burger_classnames = classNames({
        "hamburger": true,
        "hamburger--elastic": true,
        "drawer__burger": true,
        "is-active": drawer,
        [props.className]: true
    });

    return (
        <button className={burger_classnames} onClick={toggleDrawer} type="button" aria-label="Menu" aria-controls="navigation">
                <span className="hamburger-box">
                    <span className="hamburger-inner"/>
                </span>
        </button>
    )
}

const NavDrawer = () => {
    const dispatch = useDispatch();
    const {drawer, me, isAuthorized, toggleDrawer} = useDrawer();
    return(
        <div className="drawer">
            <Drawer className="drawer__menu" onChange={toggleDrawer} right open={drawer} width={320}>
                <div className = "drawer__menu-close" onClick={toggleDrawer}>
                    <IconTimes color="#FFFFFF" size={30} />
                </div>
                <div className="drawer__menu-logo">
                    <img src={require("../../images/logo.png")} alt="Trimate"/>
                </div>
                {isAuthorized && (
                    <div className="drawer__menu-user">
                        <UserAvatar src={me.avatar}/>
                        <span>{`${me.firstName} ${me.lastName}`}</span>
                    </div>
                )}
                <ul className="drawer__menu-list">
                    <li className="drawer__menu-item">
                        <a onClick={toggleDrawer} href="https://blog.trimate.pro">Blog</a>
                    </li>
                    {!isAuthorized && (
                        <Fragment>
                            <li className="drawer__menu-item">
                                <Link onClick={toggleDrawer} to={'/sign'}>Login</Link>
                            </li>
                            <li className="drawer__menu-item">
                                <Link onClick={toggleDrawer} to={'/sign/register'}>Sign up</Link>
                            </li>
                        </Fragment>
                    )}
                    {isAuthorized && (
                        <Fragment>
                            <li className="drawer__menu-item">
                                <Link onClick={toggleDrawer} to={'/chat'}>Messages</Link>
                            </li>
                            <li className="drawer__menu-item">
                                <Link onClick={toggleDrawer} to={'/liked'}>Liked</Link>{' '}
                                <span>
                                    <i className="fa fa-heart-o"
                                       aria-hidden="true"/> {(me.liked && me.liked.length) ? me.liked.length : ""}
                                </span>
                            </li>
                            <li className="drawer__menu-item">
                                <Link onClick={toggleDrawer} to={'/visited-events'}>Visited</Link>
                            </li>
                            <li className="drawer__menu-item">
                                <Link onClick={toggleDrawer} to={'/manage-events'}>Manage events</Link>
                            </li>
                            <li className="drawer__menu-item">
                                <Link onClick={toggleDrawer} to={'/new-event'}>Organise Session</Link>
                            </li>
                            <li className="drawer__menu-item">
                                <a onClick={async () => {
                                    await dispatch(logout());
                                    dispatch(push('/'));
                                    toggleDrawer();
                                }}>Log Out</a>
                            </li>
                        </Fragment>
                    )}
                </ul>
            </Drawer>
        </div>
    );
};

export default NavDrawer;
