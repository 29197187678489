import React, { Component } from 'react';
import { compose, withProps } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer';
import { withRouter  } from 'react-router-dom';
import _ from "underscore";
import './main.scss';


class Map extends Component {
	state = {
		searchAsMove: true
	};

	setPlaces = _.debounce(
		() => {
				let bounds = this.map.getBounds();
				console.log('single', this.props.single);
				if (bounds && !this.props.single) {
					this.props.getPlaces(bounds);
				} else if (!this.props.single) {
					this.setPlaces()
				}
		}, 500);

	setCenter = (LatLng) => {
		setTimeout(() => {
			if (this.map.panTo) {
				this.map.panTo(LatLng);
				this.setPlaces();
			} else {
				this.setCenter(LatLng)
			}
		}, 50)
	};

	onClusteringEnd = (e) => {
		//Опеределяем разные ли в кластере activity и красим его, если нет
		if (e && e.clusters_ && e.clusters_.length) {
			e.clusters_.forEach((cluster) => {
				if (cluster.markers_ && cluster.markers_.length > 1) {
					let colors = {
						blue: 0,
						green: 0,
						pink: 0
					};

					cluster.markers_.forEach((marker) => {
						let markerColors = marker.title.split(' ');
						markerColors.forEach((color) => {
							colors[color]++;
						})
					});

					let max = 0;
					let color = '';

					for (let i in colors) {
						if (colors[i] > max) {
							max = colors[i];
							color = i;
						} else if (colors[i] === max) {
							color = '';
						}
					}

					cluster.className_ = `cluster ${color}`
					cluster.clusterIcon_.className_ = `cluster ${color}`
				}
			})
		}
	};

	onMarkerClick = (id) => {
		this.props.history.push(`/places/${id}`)
	};

	onChangeMoveSearching = () => {
		this.setState(function(prevState, prevProps) {
			return {searchAsMove: !prevState.searchAsMove}
		})
	};

	componentDidMount() {
		// Определяем геопозицию юзера
		// if (navigator && navigator.geolocation) {
		// 	navigator.geolocation.getCurrentPosition((pos) => {
		// 		const coords = pos.coords;
		// 		this.setCenter({
		// 			lat: coords.latitude,
		// 			lng: coords.longitude,
		// 		});
		// 	})
		// }

		this.setPlaces();
	}

	render() {
		const { places, activities, single, mapRef} = this.props;
		const { searchAsMove } = this.state;

		let defaultCenter = { //London center
			lat: 51.5075058,
			lng: -0.1282692
		};

		if (single) {
			defaultCenter = {
				lat: places[0].location.coordinates[0],
				lng: places[0].location.coordinates[1]
			}
		}

		return (
			<div className="map">
				{
					single
						? null
						: (
							<div className="map__move-controls">
								<input checked={searchAsMove} onChange={this.onChangeMoveSearching} id="move-checkbox" hidden type="checkbox" />
								<label htmlFor="move-checkbox">Search as I move the map</label>
								{
									searchAsMove ? null : <div className="map__refresh" onClick={this.setPlaces}></div>
								}
							</div>
						)
				}
				<GoogleMap
					defaultZoom={10}
					defaultCenter={defaultCenter}
					onZoomChanged={(searchAsMove && !single) ? this.setPlaces : null}
					onDragEnd={(searchAsMove && !single) ? this.setPlaces : null}
					ref={el => {
						this.map = el;
						if(mapRef) mapRef(el);
					}}
				>
					<MarkerClusterer
						gridSize={70}
						enableRetinaIcons
						onClusteringEnd={this.onClusteringEnd}
						styles={[
							{url: require('../../images/black_pin.svg'), height: 84, width: 63},
							{url: require('../../images/black_pin.svg'), height: 84, width: 63},
							{url: require('../../images/black_pin.svg'), height: 84, width: 63},
							{url: require('../../images/black_pin.svg'), height: 84, width: 63},
							{url: require('../../images/black_pin.svg'), height: 84, width: 63},
						]}
					>
						{
							(places && places.length)
								? places.map((place) => {
									let colors = [];
									let color = 'black';

									activities.forEach((item) => {
										if (place.activities.includes(item.name)) {
											colors.push(item.color);
										}
									})

									if (colors.length === 1) {
										color = colors[0];
									}
									colors = colors.join(' ');

									return (
										<Marker
											onClick={() => this.onMarkerClick(place._id)}
											title={colors + ' & ' + place._id}
											key={place._id}
											position={{ lat: place.location.coordinates[0], lng: place.location.coordinates[1] }}
											icon={require('../../images/'+color+'_pin.svg')}
										/>
									)
								})
								: null
						}
					</MarkerClusterer>
				</GoogleMap>
			</div>
		)
	}
}

export default compose(
	withProps({
		loadingElement: <div style={{ height: `100%` }} />,
		containerElement: <div style={{ height: `100%` }} />,
		mapElement: <div style={{ height: `100%` }} />,
	}),
	withGoogleMap,
	withRouter
)(Map)
