import React, {Fragment, useEffect, useState} from "react";
import PropTypes from "prop-types";
import Place from "../../components/Place";
import "./main.scss";
import Preloader from "../Preloader";
import Placeholder from "../Placeholder";

const PlacesList = ({to, title, getPlaces}) => {
    const [places, setPlaces] = useState([]);
    const [loading, setLoading] = useState(true);
    const fetchPlaces = async () => {
        const events = await getPlaces();
        setPlaces([...events]);
        setLoading(false);
    };

    const parse = function(str) {
        let args = [].slice.call(arguments, 1),
            i = 0;
        return str.replace(/%s/g, () => args[i++]);
    };

    useEffect (() => {
        fetchPlaces();
    },[]);

   return(
       <div className="paged-places">
           {loading ? (
               <Preloader text="Loading..."/>
           ) : (
             <Fragment>
                 {title && (
                     <h1 className="paged-places__title">
                         {title}
                     </h1>
                 )}
                 {places && places.length ? (
                     <div className="paged-places__list">
                         {places.map((place) => (
                             <div className="paged-places__item">
                                 <Place
                                     key={ place._id }
                                     to={parse(to,place._id)}
                                     { ...place }
                                 />
                             </div>
                         ))}
                     </div>
                 ) : (
                    <Placeholder/>
                 ) }
             </Fragment>
           )}

       </div>
   );
};

PlacesList.propTypes = {
    getPlaces: PropTypes.func,
    to: PropTypes.string,
    title: PropTypes.string
};

PlacesList.defaultProps = {
    to: "/places/%s"
};

export default PlacesList;
