import React, {Fragment, useState, useRef, useImperativeHandle, forwardRef} from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
// import {Link} from 'react-router-dom'
// import classNames from 'classnames';
import "./main.scss";
import {IconTimes} from "../../components/ProjectIcons";

export default ({children, onRequestClose, ...props}) => {
    return (
        <Modal className = "trimate-modal" onRequestClose = {onRequestClose} {...props}>
            <span className="trimate-modal__close" onClick={onRequestClose}><IconTimes/></span>
            {children}
        </Modal>
    )
};
