import React from 'react';
import Select from 'react-select';

import './main.scss';

const Filters = (props) => {
    const { activities, onChangeActivity, options, onChangeSort, selectedOption, className } = props;

    return (
        <div className={`filters ${className ? className : ''}`}>
            <div className="activities">
                <div className="activities__item all blue active" onClick={onChangeActivity} data-value="all">
                    ALL
                </div>
                {
                    activities
                        ? activities.length
                            ? activities.map((activity) => {
                                let value = activity.name;

                                return (
                                    <div key={value} className={`activities__item ${activity.color}`} data-value={value} onClick={onChangeActivity}>
                                        {value}
                                    </div>
                                )
                            })
                            : null
                        : null
                }
            </div>

            <Select
                options={options}
                value={selectedOption}
                onChange={onChangeSort}
                isSearchable={false}
                className="sort"
            />
        </div>
    )
}

export default Filters
