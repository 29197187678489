import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';
import onClickOutside from "react-onclickoutside";
import "./style.scss"

const Dropdown = function(props) {
	const [isOpen, isOpenSet] = useState(false);

	const toggleDropdown = () => {
		isOpenSet(!isOpen);
	};


	Dropdown.handleClickOutside = () => isOpenSet(false);

	const dropdownContentClasses = classNames({
		'dropdown__content': true,
		'hide': !isOpen
	});

	return (
		<div className="dropdown">
			<div className="dropdown__button">
				{ props.renderButton(toggleDropdown) }
			</div>
			<div className={dropdownContentClasses}>{ props.children({toggleDropdown}) }</div>
		</div>
	);
};



Dropdown.propTypes = {
	renderButton: PropTypes.func,
};

const clickOutsideConfig = {
	handleClickOutside: () => Dropdown.handleClickOutside
};

export default onClickOutside(Dropdown, clickOutsideConfig);
