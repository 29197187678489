import React, { Component, Fragment } from "react";
import { Switch, Route, Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import {connect} from 'react-redux'

// Pages
import AppLayout from "./components/Layouts/AppLayout"
import HomeLayout from "./components/Layouts/HomeLayout"
import Browse from "./pages/Browse";
import HomePage from "./pages/Home";
import SignInPage from "./pages/SignIn";
import SignLayout from "./components/Layouts/SignLayout";
import SignUpPage from "./pages/SignUp";
import ForgotPassword from "./pages/ForgotPassword";
import NotFoundPage from "./pages/NotFound";
import Event from "./pages/Event/Event";
import OrganiseEvent from "./pages/OrganiseEvent";
import SearchField from "./components/SearchField";
import "./styles/index.scss"
import { history } from "./store/configureStore";
import {getMe, getNotifications} from "./actions/me";
import ChatPage from './pages/Chat'

import Policy from './pages/Policy'
import Terms from './pages/Terms'

import ManageEvents from "./pages/ManageEvents";
import VisitedEvents from "./pages/VisitedEvents";
import LikedPlaces from "./pages/LikedPlaces";
import ScrollToTop from "./components/ScrollToTop";
import ResetPassword from "./pages/ResetPassword"
import NavDrawer from "./components/NavDrawer";


import CookieConsent from "react-cookie-consent";


class App extends Component {

	state = {
		meFetching: false
	}

	async componentDidMount() {
		const {dispatch} = this.props;
		this.setState({meFetching: true});
		let user = await dispatch(getMe());
		this.setState({meFetching: false});
		if(user && user._id){
			dispatch(getNotifications());
			setInterval(() => {
				dispatch(getNotifications());
			}, 60000)
		}
	};

	render() {
		return (
			<Fragment>


				<Router className="app" history={history}>
					<ScrollToTop>
						<Switch>
							<HomeLayout path='/' exact component={ HomePage }/>
							<SignLayout path='/sign' exact component={ SignInPage }/>
							<SignLayout path='/sign/register' component={ SignUpPage }/>
							<SignLayout path='/sign/forgot' component={ ForgotPassword }/>
							<SignLayout path='/sign/reset-password' component={ ResetPassword }/>
							<AppLayout hideFooter path="/browse" component={ Browse } headerSearch = {<SearchField hideButton/>}/>
							<AppLayout path="/places/:id" component={ Event }/>
							<AppLayout path="/new-event" component={ OrganiseEvent }/>
							<AppLayout path="/event/:id/edit" component={ OrganiseEvent }/>
							<AppLayout path="/chat/:chat_id?" component={ ChatPage } hideFooter fullPage/>
							<AppLayout path="/manage-events" component={ ManageEvents }/>
							<AppLayout path="/visited-events" component={ VisitedEvents }/>
							<AppLayout path="/liked" component={ LikedPlaces }/>

							<AppLayout path="/terms" component={ Terms }/>
							<AppLayout path="/policy" component={ Policy }/>
							<Route component={ NotFoundPage }/>
						</Switch>
					</ScrollToTop>
				</Router>
				<NavDrawer/>

				<div>
					<CookieConsent
						acceptOnScroll={true}
						onAccept={({ acceptedByScrolling }) => {}}
						buttonText="I accept"
						buttonStyle={{
							backgroundColor: "#009AF0",
							color: "#FFFFFF",
							borderRadius: "4px",
							fontWeight: 600,
						}}
					>
						To optimise your Trimate experience, we kindly request you accept our cookies.
						Accepting our cookies improves our performance across social media and advertising, whilst allowing us to personalise and improve functions for you. To find out more or amend your cookie preferences, click <a style={{color:"#009AF0"}} href="/policy">here</a>. For further information, please review our Privacy & Cookie Policy.
						Do you accept these cookie and the processing of personal data involved?
					</CookieConsent>
				</div>
				<ToastContainer/>
				
			</Fragment>
		);
	}
}

export default connect()(App);
