import React, { Fragment } from "react";
import {connect} from 'react-redux'
import { Form, Field } from "react-final-form"
import { TextFieldAdapter } from "../../components/TextField";
import { IconFacebook } from "../../components/ProjectIcons";
import BaseButton from "../../components/Button";
import {signUp, signUpFacebook} from '../../actions/me'
import {Link} from "react-router-dom";
import "./style.scss"
import {ProfileUpload} from "../../components/FormUpload";
import {required} from "../../helpers/valdations";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'


class SignUpPage extends React.Component {


	onSubmit = async values => {
		const {dispatch} = this.props;
		console.log("VALUES", values);
		try {
			dispatch(signUp(values))
		} catch(e) {
			console.error(e);
			return new Error(e);
		}
	};

	facebookResponse = (response) => {
		const {dispatch} = this.props;
		console.log('resp', response);
		dispatch(signUpFacebook(response));
	}

	render() {
		return (
			<Fragment>
				<h1 className='text-white'>Join Trimate Today, It’s free.</h1>
				<FacebookLogin
					appId={process.env.REACT_APP_FACEBOOK_ID}
					fields="name,email,picture"
					callback={this.facebookResponse}
					render={renderProps => (
						<BaseButton icon={ IconFacebook } size="block" color="facebook" disabled={renderProps.isDisabled} onClick={renderProps.onClick}>Log in with Facebook</BaseButton>
					)}
				/>
				<p className='text-white opacity-8'>or sign up with your email address</p>

				{/* Form */ }
				<Form
					onSubmit={ this.onSubmit }
					render={ ({ handleSubmit, pristine, invalid }) => (
						<form onSubmit={ handleSubmit } className={ "signup-page__form" }>
							<div className={ "signup-page__form__upload-field" }>
								<Field name="avatar">
									{ props =>
										<Fragment>
											<ProfileUpload
												meta={props.meta}
												value = {props.input.value}
												onUpload={ ([imageUrl]) => {
													props.input.onChange(imageUrl)
												}}/>
										</Fragment>
									}
								</Field>
							</div>
							<div className={ "signup-page__form__text-field" }>
								<Field
									name="firstName"
									placeholder={ "Name" }
									fullWidth
									component={ TextFieldAdapter } />
							</div>
							<div className={ "signup-page__form__text-field" }>
								<Field
									name="lastName"
									placeholder={ "Surname" }
									fullWidth
									component={ TextFieldAdapter } />
							</div>
							<div className={ "signup-page__form__text-field" }>
								<Field name="email" placeholder={ "Email" } fullWidth
								       component={ TextFieldAdapter }/>
							</div>
							<div className={ "signup-page__form__text-field" }>
								<Field
									name="password"
									placeholder={ "Password" }
									fullWidth
									type="password"
									component={ TextFieldAdapter } />
							</div>
							<BaseButton type="submit" size='block'>
								Sign Up
							</BaseButton>
						</form>
					) }
				/>

				<p className="signup-page__privacy">
					{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
					By signing up for Trimate, you agree to the <a target="_blank" href="/terms">Terms of Service</a> and <a target="_blank" href="/policy">Privacy Policy</a>
				</p>

				<footer className="signup-page__footer">
					<span className="text-white">Already have an account? &#8195;</span>
					<BaseButton size='small' variant="outlined" color="white" to={ "/sign" }>Log in</BaseButton>
				</footer>
				{/*<ToastContainer/>*/}
			</Fragment>
		);
	}
}

export default connect(({me}) => ({me}))(SignUpPage);
