import React, {useState, useEffect, Fragment} from "react";
import PropTypes from "prop-types";
import "./main.scss";
import StarRatings from 'react-star-ratings';
import UserAvatar from "../UserAvatar";
import BaseButton from "../Button";
import WithReviewModal from "../../components/ReviewModal";
import Preloader from "../Preloader";
import moment from "moment";
import {useSelector} from "react-redux";

const Reviews = (props) => {
    const [reviews, setReviews] = useState({
        items: []
    });
    const [loading, setLoading] = useState(true);
    const me = useSelector(({me}) => me);
    const onReview = () => {
        fetchReviews();
    };

    const fetchReviews = async () => {
        if(props.getReviews){
            setLoading(true);
            const {reviews} = await props.getReviews({_id: props.place_id});
            setReviews(reviews);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchReviews();
    },[]);

    const openReviewModal = () => {
        props.openReviewModal({
            texts : {
                title: props.addReviewTitle,
                description: props.addReviewDesc,
            },
            reviewed: {
                _id: props.place_id
            },
            onReview
        });
    };

    const isReviewed = me._id ? reviews.items.some(review => review.author._id === me._id) : false;

    return (
        <div className="reviews">
            {!loading ? (
                <Fragment>
                    <div className="reviews__head">
                        <h1 className="reviews__count">{reviews.count !== 0 ? `${reviews.count} reviews`: "No reviews yet" }</h1>
                        {/*<div className=""></div>*/}
                        {reviews.count !== 0 && (
                            <Fragment>
                                <StarRatings starDimension={20} starRatedColor="#009AF0" starSpacing={2} rating={reviews.rating}/>
                                <span className="reviews__sum-rating">{reviews.rating.toFixed(1)}</span>
                            </Fragment>
                        )}
                        {!isReviewed && <BaseButton onClick={openReviewModal} className="reviews__add-review" variant="outlined">Add review</BaseButton>}
                    </div>
                    <ul className="reviews__list">
                        {reviews.items.map((review) => (
                            <li className="reviews__item">
                                <div className="review">
                                    <div className="review__meta">
                                        <div className="review__meta-left">
                                            <UserAvatar src={review.author ? review.author.avatar: null}/>
                                        </div>
                                        <div className="review__meta-right">
                                            <span className="review__user-name">{`${review.author ? review.author.firstName : null} ${review.author ? review.author.lastName : null}`}</span>
                                            <span className="review__date">{moment(review.createdAt).format("DD MMMM")}</span>
                                        </div>
                                    </div>
                                    <p className="review__text">
                                        {review.review_text}
                                    </p>
                                </div>
                            </li>
                        ))}
                    </ul>
                </Fragment>
            ) : <Preloader text="Loading reviews..."/>}
        </div>
    )
};

Reviews.propTypes = {
    addReviewTitle: PropTypes.string,
    addReviewDesc: PropTypes.string,
    getReviews: PropTypes.func
};

Reviews.defaultProps = {
    addReviewTitle: "Add a review",
    addReviewDesc: "Tell us about your experience"
};

export default WithReviewModal(Reviews);
