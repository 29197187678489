import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import PropTypes from "prop-types";
import PlacesList from "../../components/PlacesList";
import {getVisitedEvents} from "../../actions/me";
import "./main.scss"

const ManageEvents = (props) => {
    const dispatch = useDispatch();
    const getEvents = async (params) => {
        const {events} = await dispatch(getVisitedEvents(params));
        return events;
    };
    return (
        <div className="visited-event">
            <PlacesList
                title = "Visited events"
                getPlaces={getEvents}
            />
        </div>
    );
};

ManageEvents.propTypes = {

};

export default ManageEvents;
