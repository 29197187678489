import React, {createRef, Fragment, useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import _ from 'underscore'
import { getChat, sendMessage, readMessages } from '../../../actions/chat'
import ChatMessage from '../Message'
import ChatTextField from '../TextField'

import './dialog.scss'
import useInterval from '../../../utils/useInterval'
import Preloader from "../../Preloader";
import withImagesGalleryModal from "../../ImagesGallery";
import BaseButton from "../../Button";
import {IconLocation} from "../../ProjectIcons";
import classNames from "classnames";
import IconTimes from "../../ProjectIcons/times";

const ChatDialog = props => {
	const [chatLoading, setChatLoading] = useState(false);
	const [eventInfo, toggleEventInfo] = useState(false);

	const dispatch = useDispatch();
	const { chat, me } = useSelector(state => state);
	const chat_messages_view = createRef();
	const scrollToEnd = () => {
		if(chat_messages_view.current){
			const scrollHeight = chat_messages_view.current.scrollHeight;
			const height = chat_messages_view.current.clientHeight;
			const maxScrollTop = scrollHeight - height;
			chat_messages_view.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
		}
	};
	const chat_massages_length = chat.messages && chat.messages.length ? chat.messages.length : 0;
	useEffect(() => {
		console.log("chat.messages.length changed", chat_massages_length, chat_messages_view);
		scrollToEnd();
	},[chat_massages_length, chatLoading]);

	useEffect(() => {
		const fetchChat = async () => {
			setChatLoading(true);
			await dispatch(getChat(props.match.params.id));
			setChatLoading(false);
		};
		dispatch(readMessages(props.match.params.id));
		fetchChat()
	}, [props.match.params.id]);


	useInterval(() => {
		const chats = async () => {
			await dispatch(getChat(props.match.params.id));
			dispatch(readMessages(props.match.params.id));
		};
		chats()
	}, 10000);

	const submitMessage = async (msg) => {
		await dispatch(sendMessage(props.match.params.id, msg))
	};
	const toggleInfo = () => toggleEventInfo(!eventInfo);
	const event_info_classes = classNames({
		"dialog__right": true,
		"is-active": eventInfo
	});
	return (
		<div className="dialog">
			{chatLoading ? (
				<Preloader text="Loading..."/>
			) : (
				<Fragment>
					{/* Dialog section */}
					<div className="dialog__left">
						<header className="dialog__header">
							<h4>{chat.title}</h4>
							<BaseButton className="dialog__toggle-info" size="small" onClick={toggleInfo}>Event info</BaseButton>
						</header>

						{/* Messages */}
						<div className="dialog__messages" ref={chat_messages_view}>
							{chat.messages && chat.messages.length > 0 ? (
								chat.messages.map((msg, index) => {
									let author = chat.participants.find((participant) => participant._id === msg.owner);
									return (
										<ChatMessage
											openImagesModal={props.openImagesModal}
											closeImagesModal={props.openImagesModal}
											key={msg._id}
											from={author}
											msg = {msg}
											myMessage={msg.owner === me._id}
										/>
									)
								})
							) : (
								<span>No messages</span>
							)}
						</div>

						<div className="dialog__text-field">
							<ChatTextField onSubmit={((val) => submitMessage(val))}/>
						</div>

					</div>

					{/* Event section */}
					{!_.isEmpty(chat.event) && (
						<div className={event_info_classes}>
							<div className="dialog__event-info">
								<IconTimes className="dialog__toggle-info close" onClick={toggleInfo} />
								<div className="dialog__event-info__item">
									<div className="dialog__event-info__item__icon">
										<img src={require('../../../images/event/time.svg')} alt="Time" />
									</div>
									<div className="dialog__event-info__item__text">
										from {moment(chat.event.dateStart).format('LLL')}
										<br/>
										{chat.event.dateEnd && `to ${moment(chat.event.dateEnd).format('LLL')}`}
									</div>
								</div>

								<div className="dialog__event-info__item">
									<div className="dialog__event-info__item__icon">
										<img src={require('../../../images/event/direction.svg')} alt="Address" />
									</div>
									<div className="dialog__event-info__item__text">{chat.event.address}</div>
								</div>

								<div className="dialog__event-info__item">
									<div className="dialog__event-info__item__icon">
										<img src={require('../../../images/event/moneybox.svg')} alt="Price" />
									</div>
                                    {chat.event.price
                                        ? <div className="dialog__event-info__item__text">£{chat.event.price}</div>
                                        : <div className="dialog__event-info__item__text">Free event</div>
                                    }

								</div>
                                <div className="dialog__event-info__item">
                                    <BaseButton to={`/places/${chat.event._id}`}>
                                        Go to event
                                    </BaseButton>
                                </div>
							</div>
						</div>
					)}
				</Fragment>
			)}
		</div>
	)
}

ChatDialog.propTypes = {}

export default withImagesGalleryModal(ChatDialog)
