const initialState = {

};

export default (state = initialState, action = {}) => {
	switch(action.type) {
		case 'SET_PLACE':
			return action.place;
		default:
			return state
	}
}
