import React from "react";
import "./main.scss";
import {TwitterShareButton} from "react-share";
import {IconTwitter} from "../ProjectIcons";

export default () => (
    <TwitterShareButton className="share-option" url={window.location.href}>
        <IconTwitter size={30} className="share-option__icon" fill="#3B5998"/>
        <span className="share-option__name">Twitter</span>
    </TwitterShareButton>
);
