import React, {Fragment, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Switch, Route } from 'react-router'
import { Link, withRouter } from 'react-router-dom'
import Chat, { ChatDialogsList, ChatDialog, ChatDialogItem } from '../../components/ChatComponents'
import { getChats } from '../../actions/chat'
import { push } from "connected-react-router";
import Placeholder from "../../components/Placeholder";
import './chat.scss'
import classNames from "classnames";
import { useMediaQuery } from 'react-responsive'

const ChatPage = props => {
	const dispatch = useDispatch();
	const { chats, notifications = [] } = useSelector(state => state);
	const fetchChats = async () => {
		const chats = await dispatch(getChats());
	};
	const isMobile = useMediaQuery({ query: '(max-width: 1024px)' });

	useEffect(() => {
		fetchChats()
	}, []);

	const container_classnames = classNames({
		"container-fluid": chats.length > 0,
		"chat-page": true
	});

	const DialogsList = (
		<ChatDialogsList>
			{chats.length > 0 && [...chats].map(chat => {
				const notificationsCount = notifications.filter((notification) => notification.of === chat._id).length;
				return (
					<Link key={chat._id} to={`/chat/${chat._id}`}>
						<ChatDialogItem {...chat} notificationsCount = {notificationsCount}/>
					</Link>
				);
			})}
		</ChatDialogsList>
	);

	if(!isMobile) {
		return (
			<div className={container_classnames}>
				{chats.length > 0 ? (
					<Chat>
							<Fragment>
								{DialogsList}
								<Switch>
									<Route path="/chat/:id" component={ChatDialog} />
								</Switch>
							</Fragment>
					</Chat>
				) : (
					<Placeholder/>
				)}
			</div>
		)
	} else {
		return (
			<div className={container_classnames}>
				{chats.length > 0 ? (
					<Chat>
						<Fragment>
							<Switch>
								<Route path="/chat" exact render={() => DialogsList} />
								<Route path="/chat/:id" component={ChatDialog} />
							</Switch>
						</Fragment>
					</Chat>
				) : (
					<Placeholder/>
				)}
			</div>
		)
	}
}

ChatPage.propTypes = {}

export default withRouter(ChatPage)
