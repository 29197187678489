import React from "react";
import logo from "../../images/logo-blue.png"
import { Link } from "react-router-dom";

import "./style.scss";

const Footer = props => {
	return (
		<footer className="app-footer">
			<div className="container-fluid">
				<div className="app-footer__inner">
					<div className="app-footer__logo">
						<img src={ logo } alt="Trimate" className="app-footer__logo-img"/>
						<p className="app-footer__logo-copyright">© 2020 Trimate. All rights reserved.</p>
					</div>
					
					<nav className="app-footer__menu">
						<ul>
							<li className="app-footer__menu-link"><a href="mailto:info@trimate.co.uk">Contact us</a></li>
							{/* <li className="app-footer__menu-link"><Link to={ "/" }>Careers</Link></li> */}
							<li className="app-footer__menu-link"><a href="https://blog.trimate.pro">Blog</a></li>
							<li className="app-footer__menu-link"><Link to={ "/terms" }>Terms</Link></li>
							<li className="app-footer__menu-link"><Link to={ "/policy" }>Privacy</Link></li>
							{/* <li className="app-footer__menu-link"><Link to={ "/" }>Sitemap</Link></li> */}
							<li className="app-footer__menu-link"><a href="https://www.facebook.com/Trimate-100874054594204/">Facebook</a></li>
							{/* <li className="app-footer__menu-link"><Link to={ "/" }>Instagram</Link></li> */}
							{/* <li className="app-footer__menu-link"><Link to={ "/" }>Twitter</Link></li> */}
						</ul>
					</nav>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
