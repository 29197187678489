import React, {Fragment} from "react";
import "./main.scss";
import {EmailShareButton} from "react-share";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { toast } from 'react-toastify'
import {IconCopyLink} from "../../components/ProjectIcons";

export default () => (
    <CopyToClipboard text = {window.location.href} onCopy={() => toast.success("Copied to clipboard!")}>
        <div className="share-option">
            <IconCopyLink size={30} className="share-option__icon"/>
            <span className="share-option__name">Copy link</span>
        </div>
    </CopyToClipboard>
);
