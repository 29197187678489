import React from 'react'
import PropTypes from "prop-types";

const IconUser = props => (
    <svg className={props.className} width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 703 700" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M351 314C426.663 314 488 252.663 488 177C488 101.337 426.663 40 351 40C275.337 40 214 101.337 214 177C214 252.663 275.337 314 351 314ZM351 354C448.754 354 528 274.754 528 177C528 79.2456 448.754 0 351 0C253.246 0 174 79.2456 174 177C174 274.754 253.246 354 351 354Z" fill={props.fill}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M660.421 720.5C656.904 693.34 649.814 666.704 639.288 641.294C623.634 603.501 600.689 569.162 571.764 540.236C542.838 511.311 508.499 488.366 470.706 472.712C432.913 457.057 392.407 449 351.5 449C310.593 449 270.087 457.057 232.294 472.712C194.501 488.366 160.162 511.311 131.236 540.236C102.311 569.162 79.3659 603.501 63.7115 641.294C53.1865 666.704 46.0956 693.34 42.5789 720.5L660.421 720.5ZM700.717 720.5C697.003 688.075 688.783 656.26 676.244 625.987C658.579 583.341 632.688 544.592 600.048 511.952C567.408 479.312 528.659 453.421 486.013 435.756C443.367 418.092 397.66 409 351.5 409C305.34 409 259.633 418.092 216.987 435.756C174.341 453.421 135.592 479.312 102.952 511.952C70.3122 544.592 44.4209 583.341 26.7563 625.987C14.2169 656.26 5.99739 688.075 2.28335 720.5C0.765343 733.753 -1.17082e-06 747.107 0 760.5L703 760.5C703 747.107 702.235 733.753 700.717 720.5Z" fill={props.fill}/>
    </svg>
);

IconUser.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
    fill: PropTypes.string,
    className: PropTypes.string
};

IconUser.defaultProps = {
    size: 16,
    color: '#000000',
    fill: "#2CA7E0",
    className: ""
};

export default IconUser



