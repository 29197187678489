import React, {Fragment, useState} from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import PropTypes from "prop-types";


const withImagesGalleryModal = WrappedComponent => ({...props}) => {
    const [modal, setModal] = useState({isOpen: false, images: [], currentIndex: 0});
    const openModal = ({images, currentIndex}) => {
        setModal({
            images,
            currentIndex,
            isOpen: true
        });
    };
    const closeModal = () => {
        setModal({
            isOpen: false
        });
    };

    return (
        <Fragment>
            <WrappedComponent openImagesModal={openModal} closeImagesModal={closeModal} {...props}/>
            <ModalGateway>
                {modal.isOpen && (
                    <Modal onClose={closeModal}>
                        <Carousel currentIndex = {modal.currentIndex} views={modal.images} />
                    </Modal>
                )}
            </ModalGateway>
        </Fragment>
    );
};


export default withImagesGalleryModal;
