import requester from "../requester";
import { toast } from "react-toastify";
import { push } from "connected-react-router";

export const signUp = params => async dispatch => {
	try {
		const res = await requester.post("/user", { ...params });
		dispatch({
			type: "SET_ME",
			payload: res.data,
		});
		dispatch(push("/"));
		return res.data;
	} catch(e) {
		toast.error(e.response.data.code);
		console.error("signUp action error", e.response);
		return new Error(e.response);
	}
};

export const signIn = params => async dispatch => {
	try {
		const res = await requester.post("/user/login", { ...params });
		dispatch({
			type: "SET_ME",
			payload: res.data.user
		});
		dispatch(push("/"));
		return res.data;
	} catch(e) {
		toast.error(e.response.data.code);
		console.error("signIn action error", e.response);
		return new Error(e.response);
	}
};

export const signUpFacebook = params => async dispatch => {
	try {
		const res = await requester.post('/user/facebook', {...params});
		dispatch({
			type: "SET_ME",
			payload: res.data.user
		});
		dispatch(push('/'))
	} catch(e) {
		if(e.response && e.response.data.code) {
			toast.error(e.response.data.code)
		}
		console.error('signUpFacebook action error', e.response);
		return new Error(e.response);
	}
}

export const forgotPassword = params => async dispatch => {
	try {
		const res = await requester.post('/user/forgot-password', {...params});
		return res.data
	} catch(e) {
		if(e.response && e.response.data.code) {
			toast.error(e.response.data.code)
		}
		console.error('forgotPassword action error', e.response);
		return new Error(e.response);
	}
};

export const resetPassword = params => async dispatch => {
	try {
		const res = await requester.post('/user/reset-password', {...params});
		return res.data
	} catch(e) {
		if(e.response && e.response.data.code) {
			toast.error(e.response.data.code)
		}
		console.error('resetPassword action error', e.response);
		return new Error(e.response);
	}
};

export const getMe = () => async dispatch => {
	try {
		const res = await requester.get('/user/me');
		dispatch({
			type: "SET_ME",
			payload: res.data.user
		})
		return res.data.user;
	} catch(e) {
		if(e.response && e.response.status !== 401) {
			toast.error(e.response.data.code);
			console.error(e.response);
			return new Error(e.response);
		}
	}
};

export const getNotifications = () => async dispatch => {
	try {
		const res = await requester.get('/user/notifications');
		dispatch({
			type: "SET_NOTIFICATIONS",
			notifications: res.data.notifications
		})
	} catch(e) {
		if(e.response && e.response.status !== 401) {
			toast.error(e.response.data.code);
			console.error(e.response);
			return new Error(e.response);
		}
	}
};

export const likePlace = (place_id) => async dispatch => {
	try {
		const res = await requester.post(`/user/like-place/${place_id}`);
		dispatch({
			type: "SET_ME",
			payload: res.data.user
		});
		return res.data
	} catch(e) {
		if(e.response && e.response.data.code) {
			toast.error(e.response.data.code)
		}
		console.error("likeEvent action error", e.response);
		return new Error(e.response)
	}
};

export const markGoing = (place_id) => async dispatch => {
	try {
		const res = await requester.post(`/user/mark-going/${place_id}`);
		console.log("EVENT", res.data.event);
		dispatch({
			type: "SET_PLACE",
			place: res.data.event
		});
		return res.data
	} catch(e) {
		if(e.response && e.response.data.code) {
			toast.error(e.response.data.code)
		}
		console.error("likeEvent action error", e.response);
		return new Error(e.response)
	}
};

export const getManagedEvents = () => async (dispatch) => {
	try {
		const res = await requester.get("/user/managed-events");
		return res.data
	} catch(e) {
		if(e.response && e.response.data.code) {
			toast.error(e.response.data.code)
		}
		console.error("likeEvent action error", e.response);
		return new Error(e.response)
	}
};

export const getVisitedEvents = () => async (dispatch) => {
	try {
		const res = await requester.get("/user/visited-events");
		return res.data
	} catch(e) {
		if(e.response && e.response.data.code) {
			toast.error(e.response.data.code)
		}
		console.error("likeEvent action error", e.response);
		return new Error(e.response)
	}
};


export const getLikedPlaces = () => async (dispatch) => {
	try {
		const res = await requester.get("/user/liked-places");
		return res.data
	} catch(e) {
		if(e.response && e.response.data.code) {
			toast.error(e.response.data.code)
		}
		console.error("likeEvent action error", e.response);
		return new Error(e.response)
	}
};



export const logout = () => async dispatch => {
	try {
		const res = await requester.post("/user/logout/");
		dispatch({
			type: "CLEAR_ME",
		});
		return res.data
	} catch(e) {
		if(e.response && e.response.data.code) {
			toast.error(e.response.data.code)
		}
		console.error("likeEvent action error", e.response);
		return new Error(e.response)
	}
};
