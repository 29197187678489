// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import _ from 'underscore'

const useAuthorized = props => {
	const {me} = useSelector(state => state)
	const [isAuthorized, setIsAuthorized] = useState(me)

	useEffect(() => {
		setIsAuthorized(!_.isEmpty(me))
	}, [me])

	return isAuthorized
}

export default useAuthorized
