import React, { Fragment } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import "./style.scss";

const FormField = ({ value, units, placeholder, fullWidth, label, required, ...rest }) => {
    const containerClassNames = classNames({
        "form-field": true,
        "form-field__full-width": fullWidth,
    });

    const inputProps = { ...rest };

    return (
        <div className={ containerClassNames }>
            <label>
                { label }
                { required && <span className="text-danger">*</span> }
            </label>
            <div className="form-field__inner">
                <input
                    className={ "form-field__input" }
                    value={ value }
                    placeholder={ placeholder }
                    { ...inputProps }
                />
                {units && <span className="form-field__units">{units}</span>}
            </div>
        </div>
    );
};

const FormFieldAdapter = ({ input, meta, ...rest }) => {
    // console.log("input ",input, "meta ",meta);
    return (
        <Fragment>
            <FormField
                { ...rest }
                { ...input }
                onChange={ e => {
                    input.onChange(e.target.value);
                } }
            />
            { meta.error && meta.touched && <span className="form-field__error">{ meta.error }</span> }
        </Fragment>
    );
};

FormField.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    fullWidth: PropTypes.bool,
    value: PropTypes.string,
    required: PropTypes.bool,
};

export { FormFieldAdapter };

export default FormField;
