const initialState = {};

export default (state = initialState, action = {}) => {
	switch(action.type) {
		case 'SET_CHAT':
			return action.payload;
		case 'ADD_MESSAGES':
			return {...state, messages : [...state.messages, action.payload] };
		default:
			return state;
	}
}
