import React, { Fragment } from "react";
import BaseButton from "../../components/Button";
import { Form, Field } from "react-final-form"
import "./style.scss"
import {connect} from "react-redux";
import {forgotPassword} from "../../actions/me";
import { TextFieldAdapter } from "../../components/TextField";
import {toast} from "react-toastify";

class ForgotPassword extends React.Component {
	onSubmit = async ({email}) => {
		console.log("SUBMIT", email);
		const {dispatch} = this.props;
		const {status} = await dispatch(forgotPassword({email}));
		if(status === "ok"){
			toast.success("Check your email inbox");
		}
	};

	render() {
		return (
			<Fragment>
					<h1 className='text-white'>Forgot password?</h1>
					<p className='text-white opacity-8'>Enter your email address below and we'll send you a link with instructions.</p>

					{/* Form */ }
					<Form
						onSubmit={ this.onSubmit }
						render={ ({ handleSubmit, pristine, invalid }) => (
							<form onSubmit={ handleSubmit } className={ "forgot-page__form" }>
								<div className={ "forgot-page__form__text-field" }>
									<Field
										name="email"
										placeholder={ "Email" }
										fullWidth
										required
										component={ TextFieldAdapter }/>
								</div>
								<BaseButton type="submit" size='block'>
									Submit
								</BaseButton>
							</form>
						) }
					/>

					<footer className="forgot-page__footer">
						<BaseButton size='small' variant="outlined" color="white" to={'/sign'}>Log in</BaseButton>
						<BaseButton size='small' variant="outlined" color="white" to={'/sign/register'}>Sign Up</BaseButton>
					</footer>
			</Fragment>
		);
	}
}

export default connect(null)(ForgotPassword);
