import React, { Component, Fragment } from "react";
import Modal from "react-modal";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Place from "../../components/Place";
import Preloader from "../../components/Preloader";
import Filters from "../../components/Filters";
import Map from "../../components/Map";
import requester from "../../requester";
import "../../styles/index.scss";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {compose} from "redux";
import qs from "qs";
import {
	geocodeByPlaceId,
} from "react-places-autocomplete";
import Select from "react-select";
import subtypes from "../../subtypes.json";
import "./main.scss";
import {push} from "connected-react-router";
import _ from "underscore";
import { Scrollbars } from 'react-custom-scrollbars';
import SearchField from "../../components/SearchField";

library.add(faTimes);

const activities = [
	{ name: "running", color: "green" },
	{ name: "swimming", color: "blue" },
	{ name: "cycling", color: "pink" },
];

class Browse extends Component {

	state = {
		places: [],
		activeActivities: ["all"],
		searching: true,
		sort: { value: "relevance", label: "By relevance" },
		bounds: null,
		modalIsOpen: false,
		width: document.body.clientWidth,
	};

	isTagsEqual = (current = [], prev = []) => {
		console.log("current prev", current, prev);
		return !!_.isEqual(prev.sort(), current.sort());
	};

	updateQuery = (prevProps) => {
		const prevQuery = prevProps ? qs.parse(prevProps.location.search.replace("?","")) : {};
		const query = qs.parse(this.props.location.search.replace("?",""));
		if(prevQuery.placeId !== query.placeId){
			geocodeByPlaceId(query.placeId)
			.then(results => {
				console.log("result", results);
				if(results && results.length && results[0].geometry.bounds){
					this.map.fitBounds(results[0].geometry.bounds);
				} else if (results && results.length && results[0].geometry.location) {
					this.map.panTo(results[0].geometry.location);
				}
			})
			.catch(error => console.error("Error", error));
		}

		if(prevQuery.type !== query.type){
			this.getPlaces(this.state.bounds);
		}

		if(!this.isTagsEqual(query.tags, prevQuery.tags)){
			console.log("NEW TAGS", query.tags);
			this.getPlaces(this.state.bounds);
		}
	};

	componentDidMount() {
		window.addEventListener("resize", () => {
			this.setState({ width: document.body.clientWidth });
		});
		this.updateQuery();
		console.log("SUBTYPES", subtypes);
		// this.onChangeType();
	}

	componentDidUpdate(prevProps, prevState) {
		/* Узнаем изменились ли фильтры */
		if(this.state.bounds) {
			//Cравниваем массивы activeActivities
			let activitiesDiff = false;

			prevState.activeActivities.forEach(item => {
				if(!this.state.activeActivities.includes(item)) {
					activitiesDiff = true;
				}
			});

			if(
				prevState.activeActivities.length !== this.state.activeActivities.length
			) {
				activitiesDiff = true;
			}

			//Если изменились фильтры, то ищем
			if(prevState.sort !== this.state.sort || activitiesDiff) {
				this.getPlaces(this.state.bounds);
			}
		}

		if(prevProps.location.search !== this.props.location.search){
			this.updateQuery(prevProps);
		}
	}

	getPlaces = bounds => {
		const { activeActivities, sort } = this.state;
		const {type = "all", tags = []} = qs.parse(window.location.search.replace("?",""));
		this.setState({ searching: true, bounds });

		requester
			.get("/get-places", {
				params: {
					type,
					activeActivities,
					sort,
					bounds,
					tags
				},
			})
			.then(res => {
				const { places } = res.data;
				this.setState({ places, searching: false });
			});
	};

	onChangeActivity = e => {
		if(this.state.searching) {
			return null;
		}

		let allButton = document.querySelector(".activities__item.all");
		let singleButtons = document.querySelectorAll(
			".activities__item:not(.all)",
		);
		let target = e.currentTarget;

		let activeActivities = [];

		if(target === allButton) {
			//Если выбран ALL, то отключаем все остальные фильтры
			target.classList.add("active");
			if(singleButtons) {
				for(let i = 0; i < singleButtons.length; i++) {
					singleButtons[i].classList.remove("active");
				}
			}
			activeActivities = ["all"];
		} else {
			//Если выбран другой фильтр, то отключаем ALL
			target.classList.toggle("active");
			allButton.classList.remove("active");

			let activeButtons = document.querySelectorAll(".activities__item.active");

			if(activeButtons && activeButtons.length) {
				for(let i = 0; i < activeButtons.length; i++) {
					activeActivities.push(activeButtons[i].dataset.value);
				}
			} else {
				target.classList.add("active");
				return;
			}
		}

		this.setState({ activeActivities });
	};

	onChangeSort = sort => {
		this.setState({ sort });
	};
	openModal = () => {
		this.setState({ modalIsOpen: true });
	};

	closeModal = () => {
		this.setState({ modalIsOpen: false });
	};

	onTagChange = (tags) => {
		const {dispatch} = this.props;
		const tags_value = tags.map(({value}) => value);
		console.log("TAGS", tags_value);
		const query = qs.parse(window.location.search.replace("?",""));
		dispatch(push(`/browse?${qs.stringify({...query, tags: tags_value})}`));
	};

	render() {
		let { places, searching, sort, modalIsOpen, width } = this.state;

		let sortOptions = [
			{ value: "relevance", label: "By relevance" },
			{ value: "rating", label: "By rating" },
		];

		console.log("subtypes => ",subtypes);
		
		let {tags = [], type} = qs.parse(window.location.search.replace("?",""));
		console.log("type => ",type);
		let options = subtypes[type||"all"];
		console.log("options => ",options);
		if(!options){
			options = [];
		}
		// if(!subtypes){
			// subtypes = [];
		// }
		const current_tags = options.filter((option) => tags.includes(option.value));

		
		if(type === "session") {
			sortOptions.push({ value: "-date", label: "By date" });
		}
		if(type === "facility") {
			sortOptions.push({ value: "-price", label: "By price" });
		}
		
		console.log("subtypes => ",subtypes )
		return (
			<main className="app">
				<section className="places">
					{ width > 1440 ? (
						<Fragment>
							<Filters
								activities={ activities }
								options={ sortOptions }
								selectedOption={ sort }
								onChangeActivity={ this.onChangeActivity }
								onChangeSort={ this.onChangeSort }
								className="places__filters"
							/>
							<h3 className="places-subtypes__label">Tags</h3>
							<Select
								isMulti = {true}
								options = {options}
								classNamePrefix = "places-subtypes"
								placeholder = "Pick tags"
								onChange = {this.onTagChange}
								value = {current_tags}
							/>
						</Fragment>
					) : (
						<Fragment>
							<div onClick={ this.openModal } id="filters-btn">
								Filters
							</div>
							<Modal
								isOpen={ modalIsOpen }
								onRequestClose={ this.closeModal }
								className="filters__modal"
								ariaHideApp={ false }>
								<div onClick={ this.closeModal } className="filters__modal-close">
									<FontAwesomeIcon icon="times"/>
								</div>
								<h1 className="filters__modal-title">Filters</h1>
								<Filters
									activities={ activities }
									options={ sortOptions }
									selectedOption={ sort }
									onChangeActivity={ this.onChangeActivity }
									onChangeSort={ this.onChangeSort }
								/>
								<Select
									isMulti = {true}
									options = {options}
									classNamePrefix = "places-subtypes"
									placeholder = "Pick tags"
									onChange = {this.onTagChange}
									value = {current_tags}
								/>
								<div className="filters__modal-places">
									<SearchField hideButton/>
								</div>
							</Modal>
						</Fragment>
					) }
					<div className="places__list-wrapper">
						{ searching ? (
							<div className="places__preloader-wrapper">
								<Preloader className="places__preloader"/>
							</div>
						) : (
							<Scrollbars
								autoHide
								autoHideTimeout={500}
								autoHideDuration={200}
							>
								<div className={ `places__list ${ places && places.length ? "" : "full" }` }>
										{ places && places.length ? (
											places.map(place => (
												<Place
													key={ place._id }
													{ ...place }
													activities={ place.activities }
												/>
											))
										) : <div className="places__no-match">No matches</div> }
								</div>
							</Scrollbars>
						) }
					</div>
				</section>

				<section className="map-section">
					<Map
						getPlaces={ this.getPlaces }
						activities={ activities }
						places={ places }
						mapRef = {el => this.map = el}
					/>
				</section>
			</main>
		);
	}
}

export default compose(
	connect(({ places }) => ({ places })),
	withRouter
)(Browse);
