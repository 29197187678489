import React from 'react'
import PropTypes from "prop-types";

const IconCheck = props => (
    <svg className={props.className} width={props.size + 'px'} height={props.size + 'px'}  viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.84251 13.7043C7.65941 14.952 5.67184 14.952 4.48874 13.7043L0.868524 9.88661C0.381445 9.37295 0.381068 8.56808 0.867665 8.05397C1.39291 7.49903 2.27629 7.4986 2.80207 8.05303L4.48876 9.83164C5.67187 11.0792 7.65936 11.0792 8.84244 9.83159L17.1979 1.02046C17.7237 0.466016 18.6071 0.466435 19.1323 1.02138C19.6189 1.53548 19.6186 2.34035 19.1315 2.854L8.84251 13.7043Z" fill="white"/>
    </svg>
);

IconCheck.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
    className: PropTypes.string

}

IconCheck.defaultProps = {
    size: 16,
    color: '#000000',
    className: ""
}

export default IconCheck
