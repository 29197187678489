import requester from '../requester'
import { toast } from "react-toastify";

export const createEvent = (value) => async dispatch => {
	try {
		const req = await requester.post("/new-event", { ...value });
		return req.data
	} catch(e) {
		if(e.response && e.response.data.code) {
			toast.error(e.response.data.code)
		}
		console.error("createEvent action error", e.response);
		return new Error(e.response)
	}
};

export const editEvent = (event) => async dispatch => {
	try {
		const req = await requester.post(`/event`, event);
		dispatch({
			type: "SET_PLACE",
			place: req.data.event,
		});
		return req.data
	} catch(e) {
		if(e.response && e.response.data.code) {
			toast.error(e.response.data.code)
		}
		console.error("createEvent action error", e.response);
		return new Error(e.response)
	}
};


