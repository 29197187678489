import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import classNames from "classnames";
import BaseButton from "../Button";
import { IconLocation } from "../ProjectIcons";
import IconButton from "../IconButton";
import PlacesAutocomplete, {
	geocodeByPlaceId,
	getLatLng,
} from "react-places-autocomplete";
import {push} from "connected-react-router";
import qs from "qs";
import Select from "react-select";
import "./style.scss"

const typeOptions = [
	{
		label: "All",
		value: "all"
	},
	{
		label: "Training sessions",
		value: "session"
	},
	{
		label: "Training camps",
		value: "camp"
	},
	{
		label: "Facilities",
		value: "facility"
	},
	{
		label: "Recover",
		value: "recovery"
	}
];

const SearchField = (props = {}) => {
	const {placeId: initialPlaceId = null, type: initialType = null} = qs.parse(window.location.search.replace("?",""));
	const [placeId, setPlaceId] = useState(initialPlaceId);
	const [address, setAddress] = useState("");
	const [type, setType] = useState(initialType);
	const [loading, setLoading] = useState(false);

	const dispatch = useDispatch();

	const updateQuery = async () => {
		console.log("setAddress", placeId);
		if(placeId){
			if (!address) setLoading(true);
			const results = await geocodeByPlaceId(placeId);
			console.log("PLACE DECODED", results);
			if(results.length){
				setAddress(results[0].formatted_address);
			}
			if (!address) setLoading(false);
		}
	};

	useEffect(() => {updateQuery()},[]);
	useEffect(() => {updateQuery()},[placeId]);


	const handleSelect = (address, placeId) => {
		setPlaceId(placeId);
		setAddress(address);
		geocodeByPlaceId(placeId).then(res => {
			console.log("PLACE ID GEOCODE", JSON.stringify(res));
		});

		if(props.hideButton && placeId){
			const query = qs.parse(window.location.search.replace("?",""));
			dispatch(push(`/browse?${qs.stringify({...query, placeId})}`));
		}
	};

	const handleTypeSelect = ({value}) => {
		console.log("SELECTED TYPE", value, " hideButton=> ",props.hideButton)	;
		setType(value);
		if(props.hideButton){
			const query = qs.parse(window.location.search.replace("?",""));
			dispatch(push(`/browse?${qs.stringify({...query, type: value})}`));
		}
	};

	const getLocation = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((position) => {
				const geocoder = new window.google.maps.Geocoder();
				const latLng = new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude);
				geocoder.geocode({'latLng': latLng}, (results) => {
					const locality = results.find(({types}) => types.includes("locality"));
					setPlaceId(locality.place_id);
					setAddress(locality.formatted_address);
					if(props.hideButton && locality.place_id){
						// const query = {placeId: locality.place_id};
						const query = qs.parse(window.location.search.replace("?",""));
						dispatch(push(`/browse?${qs.stringify({...query, placeId: locality.place_id})}`));
					}
				});
			});
		}
	};

	const goToBrowse = () => {
		dispatch(push(`/browse?${qs.stringify({placeId,type}, { skipNulls: true})}`))
	};

	const googleMapsAutocompleteRender = ({ getInputProps, getSuggestionItemProps, suggestions }) => {
		return (
			<div className="search-field__places">
				{loading ? (
					<div className="search-field__places-loading">
						Loading
					</div>
					) : (
					<input
						placeholder="Choose city..."
						className = "search-field__input"
						{ ...getInputProps() }
					/>
				)}
				{ suggestions && suggestions.length > 0 && (
					<div className="autocomplete-dropdown-container">
						{ suggestions.map(suggestion => (
							<div { ...getSuggestionItemProps(suggestion) }>
								<span>{ suggestion.description }</span>
							</div>
						)) }
					</div>
				) }
			</div>
		)
	};

	return (
		<div className={ classNames("search-field") }>
			<div className="search-field__select-wrap">
				<Select
					isSearchable={false}
					onChange = {handleTypeSelect}
					value = {typeOptions.find(({value}) => type ? value === type : value === "all")}
					classNamePrefix = "search-select"
					options = {typeOptions}
				/>
			</div>
			<div className="search-field__input-wrap">
				<PlacesAutocomplete
					onChange={ setAddress }
					value={ address }
					onSelect={ handleSelect }
					searchOptions={{types:["(cities)"], componentRestrictions: {country: "uk"}}}
				>
					{ googleMapsAutocompleteRender }
				</PlacesAutocomplete>
				<IconButton onClick = {getLocation}>
					<IconLocation size={ 26 }/>
				</IconButton>
			</div>
			{!props.hideButton && (
				<div className="search-field__btn-wrap">
					<BaseButton className="search-field__btn" onClick = {goToBrowse}>Search</BaseButton>
				</div>
			)}
		</div>
	);
};

SearchField.propTypes = {
};

export default SearchField;
