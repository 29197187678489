import React from "react";
import './style.scss'

const NotFoundPage = props => {
	return (
		<div className={'not-found'}>
			<h1>404 NOT FOUND</h1>
		</div>
	);
};

export default NotFoundPage;