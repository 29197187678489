import React from "react";
import SearchField from "../../components/SearchField";
import './style.scss'
const HomePage = props => {
	return (
		<>
			<div className='homepage'>
				<h1>Triathlon world around you is calling</h1>
				<div className="homepage__search-field">
					<SearchField />
				</div>
			</div>
		</>
	);
};

HomePage.propTypes = {

};

export default HomePage;
