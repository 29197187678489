import request from "../requester"
import {toast} from "react-toastify";

export const getPlaces = () => async (dispatch) => {
	const data = await request.get(`/get-places`);
	dispatch({
		type: "SET_PLACES",
		events: data.events,
	});
	return data.events
};

export const getPlace = (id) => async (dispatch) => {
	try {
		const res = await request.get(`/places/${ id }`);
		dispatch({
			type: "SET_PLACE",
			place: res.data,
		})
		return res.data;
	} catch(e) {
		console.error("getPlace action error", e)
	}
};

export const getReviews = ({_id}) => async (dispatch) => {
	try {
		const res = await request.get(`/place/reviews/${_id}`);
		return res.data
	} catch(e) {
		if(e.response && e.response.data.code) {
			toast.error(e.response.data.code)
		}
		console.error("likeEvent action error", e.response);
		return new Error(e.response)
	}
};


export const sendReview = ({review, place}) => async (dispatch) => {
	try {
		const res = await request.post(`/place/reviews/${place}`, review);
		return res.data
	} catch(e) {
		if(e.response && e.response.data.code) {
			toast.error(e.response.data.code)
		}
		console.error("likeEvent action error", e.response);
		return new Error(e.response)
	}
};
