import {createStore, applyMiddleware} from "redux";
import thunk from 'redux-thunk'
import {composeWithDevTools} from "redux-devtools-extension";
import {createBrowserHistory} from 'history'
import {routerMiddleware} from 'connected-react-router'

export const history = createBrowserHistory();

export default function configureStore(reducer, state) {
	const createReduxStore = composeWithDevTools(
		applyMiddleware(thunk),
		applyMiddleware(routerMiddleware(history))
	)(createStore);

	return createReduxStore(reducer, state)
}