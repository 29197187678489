const initialState = {};

export default (state = initialState, action = {}) => {
	switch(action.type) {
		case 'SET_ME':
			return {...state, ...action.payload};
		case 'CLEAR_ME':
			return {};
		default:
			return state;
	}
}
