import React from 'react';
import moment from 'moment';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import './main.scss';

library.add(faStar);
moment.locale('en');
const color_config = {
    "swimming": "blue",
    "running": "green",
    "cycling": "pink"
};

const Place = (props) => {
    let { className, photo, status, name, price, distance, outdoor, rate, activities, tags, _id, to } = props;
    if(!to){
        to = `/places/${_id}`;
    }
    return (
        <Link to={to} className={`place ${className ? className : ''}`}>
            <div className="place-photo">
                <div className="place-photo__text">Trimate</div>
                <div className="place-photo__inner" style={ { backgroundImage: `url(${ photo })` } }/>
                {/* <div className="place-photo__like">
                    <img src={require("../../images/like.png")} alt="Like"/>
                    <img className="place-photo__like_hover" src={require("../../images/like_hover.png")} alt=""/>
                </div> */}
            </div>
            <div className="place-info">
                <div className="place-info__left">
                    <div className="place__name">{name}</div>
                    <div className="place-info__meta">
                        {(activities && activities.length)?(
                            <div className="place__activities">
                                {
                                    activities.map((item,key) => (
                                        <div key={key} className={`place__activity ${color_config[item]}`}>{item}</div>
                                    ))
                                }
                            </div>
                        ):null}
                        {(tags && tags.length)?(
                            <div className="place__activities">
                                {
                                    tags.map((tag,key) => (
                                        <div key={key} className={`place__activity`}>{tag}</div>
                                    ))
                                }
                            </div>
                        ):null}

                        <div className="place-params">
	    		    { status =="published"?null:(
			    	<div className="place-params__item">{status}</div>
			    )}
                            {
        	    		        distance
                                    ? <div className="place-params__item">{distance}</div>
            			            : null
                            }
                            {
        	    		        (outdoor !== undefined && outdoor !== null)
                                    ? <div className="place-params__item">{outdoor ? 'outdoor' : 'indoor'}</div>
            			            : null
                            }
                            {
        	    		        rate
                                    ? <div className="place-params__item place-params__item_rate">
                                          <FontAwesomeIcon className="place-params__star" icon="star" />
                                          {rate.toFixed(1)}
                                      </div>
            			            : null
                            }
                        </div>
                    </div>
                </div>
                <div className="place-info__right">
                    <div className="place__price">
                        {
                            price
                                ? '£' + price
                                : 'Free'
                        }
                    </div>
                </div>
            </div>
        </Link>
    )
};

export default Place
