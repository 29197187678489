const initialState = [];

export default (state = initialState, action = {}) => {
	switch(action.type) {
		case 'SET_CHATS':
			return [...action.payload];
		default:
			return state;
	}
}
