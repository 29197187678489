import React from 'react'
import PropTypes from "prop-types";

const IconTwitter = props => (
    <svg className={props.className} width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M32.7389 8.4751C31.6151 8.97354 30.4075 9.31036 29.1399 9.46176C30.4335 8.6862 31.4273 7.45822 31.8951 5.99482C30.6843 6.71294 29.3431 7.2345 27.9157 7.51546C26.7727 6.2975 25.144 5.53662 23.3417 5.53662C19.881 5.53662 17.075 8.34229 17.075 11.803C17.075 12.2941 17.1304 12.7724 17.2373 13.231C12.0293 12.9697 7.41172 10.4749 4.32104 6.68359C3.78162 7.60909 3.47257 8.68547 3.47257 9.83397C3.47257 12.008 4.57883 13.9261 6.2603 15.0499C5.23306 15.0173 4.26679 14.7355 3.42197 14.2661C3.42137 14.2923 3.42137 14.3184 3.42137 14.3449C3.42137 17.3811 5.5815 19.9137 8.44819 20.4896C7.92239 20.6328 7.36876 20.7095 6.79726 20.7095C6.39345 20.7095 6.00092 20.6701 5.61822 20.597C6.41569 23.0865 8.72989 24.8984 11.4721 24.9488C9.32747 26.6297 6.62547 27.6314 3.68945 27.6314C3.18363 27.6314 2.68486 27.6018 2.19458 27.5437C4.96784 29.3217 8.26179 30.3593 11.8006 30.3593C23.327 30.3593 29.6302 20.8105 29.6302 12.5295C29.6302 12.2579 29.6241 11.9877 29.6121 11.7188C30.8365 10.8354 31.8989 9.7317 32.7389 8.4751Z" fill="#2CA7E0"/>
    </svg>
);

IconTwitter.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
    fill: PropTypes.string,
    className: PropTypes.string
};

IconTwitter.defaultProps = {
    size: 16,
    color: '#000000',
    fill: "#2CA7E0",
    className: ""
};

export default IconTwitter



