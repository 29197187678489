import ChatDialogsList from './DialogsList'
import ChatDialog from './Dialog'
import ChatDialogItem from './DialogItem'
import React, {useEffect, useState} from 'react'

import "./chat-components.scss"

const Chat = props => {
	const [windowHeight, setWindowHeight] = useState(window.innerHeight)
	useEffect(() => {
		const handleResize = () => setWindowHeight(window.innerHeight);
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	});
	return (
		//style={{height: (windowHeight - (window.innerHeight * 20 / 100)) + 'px'}}
		<div className="chat">
				{props.children}
		</div>
	)
}

Chat.propTypes = {

}

export default Chat
export {
	ChatDialogsList,
	ChatDialog,
	ChatDialogItem
}
