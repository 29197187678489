import React from "react";
import PropTypes from "prop-types";
import "./main.scss";

const Placeholder = props => (
    <div className="page-placeholder">
        <img alt="Flip" src={props.imageSrc}/>
        <h1 className="page-placeholder__title">{props.text}</h1>
    </div>
);


Placeholder.propTypes = {
    imageSrc: PropTypes.string,
    text: PropTypes.string
};

Placeholder.defaultProps = {
    imageSrc: require("../../images/flip.gif"),
    text: "NOTHING HERE YET"
};

export default Placeholder;





