import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import BaseButton from "../Button";
import "./main.scss";

const SlideButton = ({onClick, className, config}) => (
    <div className={classNames(className,"slide-btn")} onClick={onClick}>
        <BaseButton className = {className} {...config.left}>{config.left.title}</BaseButton>
        <BaseButton className = {className} {...config.right}>{config.right.title}</BaseButton>
    </div>
);

SlideButton.propTypes = {
    config: PropTypes.object,
    className: PropTypes.string,
};

SlideButton.defaultProps = {
    className: "",
    config: {left: {}, right: {}}
};

export default SlideButton
