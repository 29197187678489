import React, {Fragment, useState} from "react";
import PropTypes from "prop-types";
import Modal from "../../components/Modal";
// import {Link} from 'react-router-dom'
// import classNames from 'classnames';
import {FacebookShare, TwitterShare, WhatsAppShare, EmailShare, CopyToClipboard} from "../ShareOptions";

import "./main.scss";

export default WrappedComponent => ({...props}) => {
    const [open, setOpen] = useState(false);
    const openModal = () => setOpen(true);
    const closeModal = () => setOpen(false);
    return (
      <Fragment>
          <Modal isOpen={open} onRequestClose={closeModal}>
              <div className="share-modal">
                  <h1 className="share-modal__title">Share</h1>
                  <p className="share-modal__desc">
                      Share out this awesome event on Trimate
                  </p>
                  <ul className="share-modal__options-list">
                      <li className="share-modal__options-item">
                            <FacebookShare/>
                      </li>
                      <li className="share-modal__options-item">
                          <TwitterShare/>
                      </li>
                      <li className="share-modal__options-item">
                          <WhatsAppShare/>
                      </li>
                      <li className="share-modal__options-item">
                            <EmailShare/>
                      </li>
                      <li className="share-modal__options-item">
                          <CopyToClipboard/>
                      </li>
                  </ul>
              </div>
          </Modal>
          <WrappedComponent openShareModal = {openModal} closeShareModal = {closeModal} {...props}/>
      </Fragment>
  )
};
